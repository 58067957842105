import { useForm } from 'react-hook-form'
import React from 'react'
import Swal from 'sweetalert2'
import * as Server from '../Server'

const LoginForm = () => {
    const { register, handleSubmit } = useForm()
    /*function tokenCaducado() {
        var tokenExpiration = localStorage.getItem('tokenExpiration')
        // Comprobar si el token de expiración está presente
        if (tokenExpiration) {
            var expirationTime = parseInt(tokenExpiration)
            return new Date().getTime() >= expirationTime
        }
        return true // Si no hay token de expiración, considerarlo caducado
    }*/
    /*var token = localStorage.getItem('token')
    if (token && !tokenCaducado()) {
        //vigente
        window.location.href = '/Caja'
    } else {
        if (token) {
            //
        } else {
            //
        }
    }*/
    const onSubmit = async (data) => {
        await Server.login(data)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'success') {
                    localStorage.setItem('token', data.token)
                    var expirationDate = new Date()
                    expirationDate.setSeconds(
                        expirationDate.getSeconds() + 6000
                    )
                    localStorage.setItem(
                        'tokenExpiration',
                        expirationDate.getTime().toString()
                    )
                    //window.location.href = '/Caja';
                    var token = localStorage.getItem('token')
                    var tokenExpiration =
                        localStorage.getItem('tokenExpiration')
                    // Comprobar si los datos quedaron almacenados en LocalStorage
                    if (token && tokenExpiration) {
                        window.location.href = '/Caja'
                    } else {
                    }
                } else {
                    let invalid

                    if (data.message === 'Invalid User') {
                        invalid = 'El usuario ingresado no existe'
                    } else if (data.message === 'Invalid Password') {
                        invalid = 'Contraseña incorrecta intente nuevamente'
                    } else {
                        invalid =
                            'Se ha presentado un error inesperado, intente nuevamente'
                    }
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `${invalid}`,
                    })
                }
            })
    }
    return (
        <div className="text-center">
            <form className="form-signin" onSubmit={handleSubmit(onSubmit)}>
                <h1 className="h3 mb-3 font-weight-normal">Inicio de Sesión</h1>
                <input
                    className="form-control"
                    {...register('username')}
                    placeholder="Username"
                />
                <input
                    className="form-control"
                    {...register('password')}
                    placeholder="Password"
                    type="password"
                />
                <button className="btn btn-lg btn-primary" type="submit">
                    Sign in
                </button>
            </form>
        </div>
    )
}
export default LoginForm
