const API_URL = 'https://pc-leon.com/pos/api/'
//const API_URL = 'http://127.0.0.1:8000/pos/api/'

//Caja
export const ultimoRegistro = async () => {
    return await fetch(API_URL + 'caja/')
}

export const registrarOperacion = async (
    operacion,
    fechaCierre,
    valor,
    observaciones
) => {
    return await fetch(API_URL + 'caja/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            operacion: String(operacion).trim(),
            fecha_cierre: fechaCierre,
            valor: parseInt(valor),
            observaciones: String(observaciones).trim(),
        }),
    })
}

//Roles
export const listaRoles = async () => {
    return await fetch(API_URL + 'roles/')
}

export const listaRol = async (id) => {
    return await fetch(API_URL + 'rol/' + id)
}

export const crearRol = async (rol) => {
    return await fetch(API_URL + 'roles/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            nombre: String(rol.nombre).trim(),
        }),
    })
}

export const modificarRol = async (rol, id) => {
    return await fetch(API_URL + 'rol/' + id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            nombre: String(rol.nombre).trim(),
        }),
    })
}

export const eliminarRol = async (id) => {
    return await fetch(API_URL + 'rol/' + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//Empleados
export const listaEmpleados = async () => {
    return await fetch(API_URL + 'empleados/')
}

export const listaEmpleado = async (id) => {
    return await fetch(API_URL + 'empleado/' + id)
}

export const crearEmpleado = async (empleado) => {
    return await fetch(API_URL + 'empleados/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            empleado: String(empleado.empleado).trim(),
        }),
    })
}

export const modificarEmpleado = async (empleado, id) => {
    return await fetch(API_URL + 'empleado/' + id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            empleado: String(empleado.empleado).trim(),
        }),
    })
}

export const eliminarEmpleado = async (id) => {
    return await fetch(API_URL + 'empleado/' + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//Mesas
export const listaMesas = async () => {
    return await fetch(API_URL + 'mesas/')
}

export const listaMesa = async (id) => {
    return await fetch(API_URL + 'mesa/' + id)
}

export const crearMesa = async (mesa) => {
    return await fetch(API_URL + 'mesas/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            numero: parseInt(mesa.numero),
            descripcion: String(mesa.descripcion).trim(),
        }),
    })
}

export const modificarMesa = async (mesa, id) => {
    return await fetch(API_URL + 'mesa/' + id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            numero: parseInt(mesa.numero),
            descripcion: String(mesa.descripcion).trim(),
        }),
    })
}

export const eliminarMesa = async (id) => {
    return await fetch(API_URL + 'mesa/' + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//Clientes
export const listaClientes = async () => {
    return await fetch(API_URL + 'clientes/')
}

export const listaCliente = async (id) => {
    return await fetch(API_URL + 'cliente/' + id)
}

export const crearCliente = async (cliente) => {
    return await fetch(API_URL + 'clientes/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            cliente: String(cliente.cliente).trim(),
        }),
    })
}

export const modificarCliente = async (cliente, id) => {
    return await fetch(API_URL + 'cliente/' + id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            cliente: String(cliente.cliente).trim(),
        }),
    })
}

export const eliminarCliente = async (id) => {
    return await fetch(API_URL + 'cliente/' + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//Categorias
export const listaCategorias = async () => {
    return await fetch(API_URL + 'categorias/')
}

export const listaCategoria = async (id) => {
    return await fetch(API_URL + 'categoria/' + id)
}

export const crearCategoria = async (categoria) => {
    return await fetch(API_URL + 'categorias/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            categoria: String(categoria.categoria).trim(),
        }),
    })
}

export const modificarCategoria = async (categoria, id) => {
    return await fetch(API_URL + 'categoria/' + id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            categoria: String(categoria.categoria).trim(),
        }),
    })
}

export const eliminarCategoria = async (id) => {
    return await fetch(API_URL + 'categoria/' + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//Productos
export const listaProductos = async () => {
    return await fetch(API_URL + 'productos/')
}
export const listaProducto = async (id) => {
    return await fetch(API_URL + 'producto/' + id)
}
export const crearProducto = async (newProduct) => {
    return await fetch(API_URL + 'productos/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            nombre: String(newProduct.nombre).trim(),
            medida: String(newProduct.medida).trim(),
            categoria: parseInt(newProduct.categoria),
            costo: parseInt(newProduct.costo),
            precio: parseInt(newProduct.precio),
            descripcion: String(newProduct.descripcion).trim(),
            cantidad_stock: parseInt(0),
            quantity: parseInt(1),
        }),
    })
}
export const modificarProducto = async (modProduct, id) => {
    return await fetch(API_URL + 'producto/' + id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            nombre: String(modProduct.nombre).trim(),
            categoria: parseInt(modProduct.categoria),
            medida: String(modProduct.medida).trim(),
            costo: parseInt(modProduct.costo),
            precio: parseInt(modProduct.precio),
            descripcion: String(modProduct.descripcion).trim(),
        }),
    })
}
export const eliminarProducto = async (id) => {
    return await fetch(API_URL + 'producto/' + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//Ventas
export const registroVentas = async (params) => {
    const url = new URL(API_URL + 'ventas/')
    url.search = new URLSearchParams(params).toString()
    return await fetch(url)
}

export const crearVenta = async (newVenta, metodoPago) => {
    return await fetch(API_URL + 'ventas/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            mesa: parseInt(1),
            id_e_id: parseInt(1),
            total: parseFloat(newVenta),
            metodoPago: /* "Efectivo" */ String(metodoPago).trim(),
            id_cliente_id: parseInt(1),
        }),
    })
}

export const crearDetalleVenta = async (newVenta) => {
    return await fetch(API_URL + 'detalle_ventas/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id_producto_terminado_id: parseInt(newVenta.id_producto),
            cantidad: parseInt(newVenta.quantity),
        }),
    })
}

//Proveedores
export const listaProveedores = async () => {
    return await fetch(API_URL + 'proveedores/')
}

export const listaProveedor = async (id) => {
    return await fetch(API_URL + 'proveedor/' + id)
}

export const crearProveedor = async (proveedor) => {
    return await fetch(API_URL + 'proveedores/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            nombre: String(proveedor.nombre).trim(),
        }),
    })
}

export const modificarProveedor = async (proveedor, id) => {
    return await fetch(API_URL + 'proveedor/' + id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            nombre: String(proveedor.nombre).trim(),
        }),
    })
}

export const eliminarProveedor = async (id) => {
    return await fetch(API_URL + 'proveedor/' + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//Ingredientes
export const listaIngredientes = async () => {
    return await fetch(API_URL + 'ingredientes/')
}

export const listaIngrediente = async (id) => {
    return await fetch(API_URL + 'ingrediente/' + id)
}

export const crearIngrediente = async (newIngredient) => {
    return await fetch(API_URL + 'ingredientes/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            nombre: String(newIngredient.nombre).trim(),
            descripcion: String(newIngredient.descripcion).trim(),
            proveedor: parseInt(newIngredient.proveedor),
            medida: String(newIngredient.medida).trim(),
            costo: parseInt(newIngredient.costo),
            precio: parseInt(newIngredient.precio),
            cantidad_stock: parseInt(0),
            quantity: parseInt(1),
        }),
    })
}

export const modificarIngrediente = async (modIngredient, id) => {
    return await fetch(API_URL + 'ingrediente/' + id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            nombre: String(modIngredient.nombre).trim(),
            descripcion: String(modIngredient.descripcion).trim(),
            proveedor: parseInt(modIngredient.proveedor),
            medida: String(modIngredient.medida).trim(),
            costo: parseInt(modIngredient.costo),
            precio: parseInt(modIngredient.precio),
            cantidad_stock: parseInt(0),
            quantity: parseInt(1),
        }),
    })
}

export const eliminarIngrediente = async (id) => {
    return await fetch(API_URL + 'ingrediente/' + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

//Gastos
export const registroGastos = async (params) => {
    const url = new URL(API_URL + 'gastos/')
    url.search = new URLSearchParams(params).toString()
    return await fetch(url)
}

export const crearGasto = async (newGasto, metodoPago) => {
    return await fetch(API_URL + 'gastos/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            total: parseFloat(newGasto),
            metodoPago: String(metodoPago).trim(),
            id_prov_id: parseInt(1),
        }),
    })
}

export const crearDetalleGasto = async (newGasto) => {
    return await fetch(API_URL + 'detalle_gastos/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            id_ingrediente_id: parseInt(newGasto.id_ingrediente),
            costo: parseInt(newGasto.costo),
            cantidad: parseInt(newGasto.quantity),
        }),
    })
}

//Login
export const login = async (data) => {
    return await fetch(API_URL + 'login/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
}
