import Swal from 'sweetalert2'
import * as Server from '../Server'

const formatoMiles = (input) => {
    // Obtén el valor actual del campo de entrada
    let num = input.value.replace(/\D/g, '')

    // Aplica el formato de separación de miles
    num = num.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

    // Asigna el valor formateado al campo de entrada
    input.value = num
}

const crearProducto = async (id) => {
    let miProducto = []
    let nombre
    let id_categoria
    let costo
    let precio
    let descripcion
    try {
        if (id) {
            const res = await Server.listaProducto(id)
            const data = await res.json()
            miProducto = data.productos[0]
            nombre = miProducto.nombre
            id_categoria = miProducto.categoria_id
            costo = miProducto.costo
            precio = miProducto.precio
            descripcion = miProducto.descripcion
        } else {
            nombre = ''
            id_categoria = ''
            costo = ''
            precio = ''
            descripcion = ''
        }
        const messages = {
            required: 'Este campo es obligatorio',
            nombre: 'Debe ingresar un nombre',
            categoria: 'Debe seleccionar una categoria',
            costo: 'Debes introducir un valor correcto en el costo',
            precio: 'Debes introducir un valor correcto en el precio',
        }

        const res = await Server.listaCategorias()
        const data = await res.json()
        let options = ''
        data.categorias.forEach((categoria) => {
            options += `<option value="${categoria.id_categoria}" ${
                categoria.id_categoria === id_categoria ? 'selected' : ''
            }>${categoria.categoria}</option>`
        })

        Swal.fire({
            title: (id ? 'Modificar' : 'Crear') + ' Producto',
            html: `
                <form id="Formulario" class="row g-3">
                    <div class="col-md-9">
                        <label for="inputEmail4" class="form-label">Nombre</label>
                        <input type="text" class="form-control" id="nombreProducto" Value="${nombre}" required data-validation-message="nombre">
                    </div>

                    <div class="col-md-3">
                    <label for="inputState" class="form-label">Categoria</label>
                    <select id="categoriaProducto" class="form-select">
                    ${options}
                    </select>
                </div>
                    
                    <div class="col-6">
                        <label for="inputAddress" class="form-label">Costo</label>
                        <div class="input-group mb-3">
                            <span class="input-group-text">$</span>
                            <input type="text" class="form-control" id="costoProducto" inputmode="numeric" pattern="[0-9.,]+" Value="${costo?.toLocaleString(
                                'es-CO'
                            )}"  required data-validation-message="costo">
                        </div>
                    </div>
                    
                    <div class="col-6">
                        <label for="inputAddress" class="form-label">Precio</label>
                        <div class="input-group mb-3">
                            <span class="input-group-text">$</span>
                            <input type="text" class="form-control" id="precioProducto" inputmode="numeric" pattern="[0-9.,]+" Value="${precio?.toLocaleString(
                                'es-CO'
                            )}" required data-validation-message="precio">
                        </div>
                    </div>

                    <div class="form-label">
                        <label for="inputAddress" class="form-label">Descripción</label>
                        <textarea class="form-control" placeholder="Descripción" id="descripcionProducto">${descripcion}</textarea>
                    </div>
                </form>`,
            customClass: 'my-swal my-validation-message',
            showCancelButton: true,
            preConfirm: () => {
                const form = document.querySelector('#Formulario')
                const inputs = form.querySelectorAll('input')
                let isValid = true
                let validacionPrecioCosto = true

                for (const input of inputs) {
                    if (!input.checkValidity()) {
                        isValid = false
                        const validationMessage = input.getAttribute(
                            'data-validation-message'
                        )
                        const errorMessage = messages[validationMessage]
                        Swal.showValidationMessage(errorMessage)
                        validacionPrecioCosto = false
                        break
                    }
                }

                if (validacionPrecioCosto) {
                    const costo = document
                        .getElementById('costoProducto')
                        .value.replace('.', '')
                    const precio = document
                        .getElementById('precioProducto')
                        .value.replace('.', '')

                    if (precio <= costo) {
                        Swal.showValidationMessage(
                            'El precio debe ser mayor al costo'
                        )
                        isValid = false
                    }
                }

                return isValid
            },
        }).then(async (result) => {
            if (result.value) {
                let res
                const nombre = document.getElementById('nombreProducto').value
                const categoria =
                    document.getElementById('categoriaProducto').value
                const costo = document
                    .getElementById('costoProducto')
                    .value.replace('.', '')
                const precio = document
                    .getElementById('precioProducto')
                    .value.replace('.', '')
                const descripcion = document.getElementById(
                    'descripcionProducto'
                ).value

                const producto = {
                    nombre,
                    categoria,
                    costo,
                    precio,
                    descripcion,
                }
                if (id) {
                    res = await Server.modificarProducto(producto, id)
                } else {
                    res = await Server.crearProducto(producto)
                }

                if (res.status === 200) {
                    Swal.fire({
                        html: `¡El producto: <b>${nombre}</b> ha sido ${
                            id ? 'modificado' : 'creado'
                        } exitosamente!`,
                        icon: 'success',
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload()
                        }
                    })
                } else if (res.status === 409) {
                    Swal.fire({
                        html: '¡El producto: <b>' + nombre + '</b> ya existe!',
                        icon: 'warning',
                        allowOutsideClick: false,
                    })
                } else {
                    Swal.fire({
                        html:
                            '¡El producto: <b>' +
                            nombre +
                            '</b> No ha sido creado!',
                        icon: 'error',
                        allowOutsideClick: false,
                    })
                }
            }
        })

        // Agrega la función formatoMiles al evento input de los elementos de entrada de costo y precio
        const costoProducto = document.getElementById('costoProducto')
        costoProducto.addEventListener('input', () =>
            formatoMiles(costoProducto)
        )

        const precioProducto = document.getElementById('precioProducto')
        precioProducto.addEventListener('input', () =>
            formatoMiles(precioProducto)
        )
    } catch (err) {
        console.error(err)
    }
}

export default crearProducto
