import React, { useState, useEffect } from 'react'
import * as Server from '../Server'

const messages = {
    required: 'Este campo es obligatorio',
    costo: 'Debes introducir un valor numérico',
}

export const Listingredientes = ({
    allData,
    setAllData,
    countData,
    setCountData,
    total,
    setTotal,
}) => {
    const [ingredientess, setIngredientes] = useState([])
    const [proveedores, setProveedores] = useState([])
    const [proveedorSeleccionado, setProveedorSeleccionado] = useState(1)
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')
    const [costoIngredientes, setCostoIngredientes] = useState({})
    const [editarCosto, setEditarCosto] = useState(false)
    const [costoValido, setCostoValido] = useState(true)
    const [showWarning, setShowWarning] = useState(false)

    const listaproveedores = async () => {
        try {
            const res = await Server.listaProveedores()
            const data = await res.json()
            setProveedores(data.proveedores)
        } catch (err) {
            console.error(err)
        }
    }

    const listingredientes = async () => {
        try {
            const res = await Server.listaIngredientes()
            const data = await res.json()
            const costoInicial = {}
            data.ingredientes.forEach((ingredient) => {
                costoInicial[ingredient.id_ingrediente] = ingredient.costo
            })
            setIngredientes(data.ingredientes)
            setCostoIngredientes(costoInicial)
        } catch (err) {
            console.error(err)
        }
    }

    const searcher = (e) => {
        setSearch(e.target.value)
    }

    const costoIngredientesOnChange = (e, idIngrediente) => {
        const costo = e.target.value.replace(/[^0-9]+/g, '')
        if (costo === '' || /^[0-9]+$/.test(costo)) {
            setCostoValido(true)
            setShowWarning(false)
            setCostoIngredientes({
                ...costoIngredientes,
                [idIngrediente]: costo,
            })
        } else {
            setCostoValido(false)
        }
    }

    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-CO').format(value)
    }

    const nextPage = () => {
        if (
            ingredientess.filter((poke) => poke.nombre.includes(search))
                .length >
            currentPage + 12
        )
            setCurrentPage(currentPage + 12)
    }

    const prevPage = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 12)
    }

    const catFiltro = (e) => {
        setProveedorSeleccionado(e.target.value)
    }

    const normalizadSearch = search
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')

    useEffect(() => {
        listingredientes()
        listaproveedores()
    }, [])

    const handleEditarCosto = (idIngrediente) => {
        const updatedIngredientes = ingredientess.map((item) =>
            item.id_ingrediente === idIngrediente
                ? { ...item, editable: true }
                : item
        )
        setIngredientes(updatedIngredientes)
    }

    const handleActualizarCosto = async (idIngrediente) => {
        if (!costoValido) {
            setShowWarning(true)
            return
        }

        const updatedIngredientes = ingredientess.map((item) =>
            item.id_ingrediente === idIngrediente
                ? {
                      ...item,
                      editable: false,
                      costo: parseInt(
                          costoIngredientes[item.id_ingrediente],
                          10
                      ),
                  }
                : item
        )
        setIngredientes(updatedIngredientes)
        setEditarCosto(false)
        console.log('editarCosto')
        console.log(editarCosto)
        const ingredienteActualizado = updatedIngredientes.find(
            (item) => item.id_ingrediente === idIngrediente
        )
        let resUpIn
        resUpIn = await Server.modificarIngrediente(
            ingredienteActualizado,
            idIngrediente
        )
        console.log('resUpIn')
        console.log(resUpIn)
    }

    const onAddIngredient = (ingredient, costoIngrediente) => {
        if (
            allData.find(
                (item) => item.id_ingrediente === ingredient.id_ingrediente
            )
        ) {
            const ingredientes = allData.map((item) =>
                item.id_ingrediente === ingredient.id_ingrediente
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
            )
            setTotal(total + costoIngrediente * ingredient.quantity)
            setCountData(countData + ingredient.quantity)
            return setAllData([...ingredientes])
        }
        setTotal(total + costoIngrediente * ingredient.quantity)
        setCountData(countData + ingredient.quantity)
        setAllData([...allData, ingredient])
    }

    return (
        <>
            <div className="row">
                <div className="col-md-9 mb-4">
                    <label htmlFor="buscar" className="form-label">
                        Buscar
                    </label>
                    <input
                        type="text"
                        className="mb-2 form-control"
                        placeholder="Nombre del Ingrediente"
                        value={search}
                        onChange={searcher}
                    />
                </div>
                <div className="col-md-3 mb-4">
                    <label htmlFor="proveedor" className="form-label">
                        Proveedor
                    </label>
                    <select
                        className="form-select"
                        value={parseInt(proveedorSeleccionado)}
                        onChange={catFiltro}
                    >
                        {proveedores.map((proveedoresItem) => (
                            <option
                                key={proveedoresItem.id_prov}
                                value={proveedoresItem.id_prov}
                            >
                                {proveedoresItem.nombre}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <button className="mb-2 btn btn-primary" onClick={prevPage}>
                Anteriores
            </button>
            &nbsp;
            <button className="mb-2 btn btn-primary" onClick={nextPage}>
                Siguientes
            </button>
            <div className="row">
                {ingredientess.map((ingredient, index) => {
                    if (
                        (parseInt(proveedorSeleccionado) === 1 ||
                            ingredient.proveedor_id ===
                                parseInt(proveedorSeleccionado)) &&
                        ingredient.nombre
                            .toLowerCase()
                            .includes(normalizadSearch.toLowerCase())
                    ) {
                        const costoIngrediente = parseInt(
                            costoIngredientes[ingredient.id_ingrediente],
                            10
                        )
                        const formattedCosto =
                            typeof costoIngrediente === 'number'
                                ? formatCurrency(costoIngrediente)
                                : ''

                        return (
                            <div
                                className="col-md-3 mb-4"
                                key={ingredient.id_ingrediente}
                            >
                                <div className="card card-body">
                                    <h5
                                        className="cart-title-custom"
                                        title={ingredient.nombre}
                                    >
                                        {ingredient.nombre}
                                    </h5>
                                    <p className="card-text">
                                        <strong>Medida: </strong>
                                        {ingredient.medida}
                                    </p>
                                    <div className="input-group">
                                        <strong className="input-group-text col-md-3 mb-2 ">
                                            Costo: $
                                        </strong>
                                        {ingredient.editable ? (
                                            <input
                                                type="text"
                                                className={`col-md-5 mb-2 form-control ${
                                                    showWarning &&
                                                    !costoValido &&
                                                    'is-invalid'
                                                }`}
                                                placeholder="Costo del Ingrediente"
                                                value={formattedCosto || ''}
                                                onChange={(e) =>
                                                    costoIngredientesOnChange(
                                                        e,
                                                        ingredient.id_ingrediente
                                                    )
                                                }
                                                inputMode="numeric"
                                            />
                                        ) : (
                                            <p className="col-md-5 mb-2 input-group-text">
                                                {formattedCosto}
                                            </p>
                                        )}
                                        {ingredient.editable ? (
                                            <button
                                                className="btn btn-primary col-md-4 mb-2"
                                                onClick={() =>
                                                    handleActualizarCosto(
                                                        ingredient.id_ingrediente
                                                    )
                                                }
                                            >
                                                Actualizar
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-primary col-md-4 mb-2"
                                                onClick={() =>
                                                    handleEditarCosto(
                                                        ingredient.id_ingrediente
                                                    )
                                                }
                                            >
                                                Editar costo
                                            </button>
                                        )}
                                    </div>
                                    {showWarning && !costoValido && (
                                        <div className="invalid-feedback">
                                            {messages.costo}
                                        </div>
                                    )}
                                    <button
                                        className="btn btn-primary"
                                        disabled={ingredient.editable}
                                        onClick={() =>
                                            onAddIngredient(
                                                ingredient,
                                                costoIngredientes[
                                                    ingredient.id_ingrediente
                                                ]
                                            )
                                        }
                                    >
                                        Añadir al carrito
                                    </button>
                                </div>
                            </div>
                        )
                    }
                    return null
                })}
            </div>
        </>
    )
}

export default Listingredientes
