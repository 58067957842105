import ReactDOM from 'react-dom/client'
//import { createRoot } from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
//Components:
import Navbar from './components/Navbar/Navbar'
import LoginForm from './components/Login/LoginForm'
import ProductsList from './components/Products/ProductsList'
import ProductForm from './components/Products/ProductForm'
import IngredientsList from './components/Ingredients/IngredientsList'
import IngredientForm from './components/Ingredients/IngredientForm'
import FormCaja from './components/Caja/FormCaja'
import AdminProveedores from './components/Proveedores/AdminProveedores'
import Maker from './components/Maker'
import { Carrito } from './components/Carrito'
import SesionControl from './components/SesionControl'
import Listamesas from './components/Mesas/ListaMesas'

const App = () => {
    const handleLoading = (isLoading) => {
        if (isLoading) {
            Swal.fire({
                title: 'Cargando',
                text: 'Por favor, espera...',
                showCancelButton: false,
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                icon: 'info',
                onOpen: () => {
                    Swal.showLoading()
                },
            })
        } else {
            Swal.close()
        }
    }

    return (
        <BrowserRouter>
            <div className="mx-5 my-3">
                <Navbar />
                <Routes>
                    <Route path="/" element={<Navigate to="/login" />} />
                    <Route
                        path="/login"
                        element={<LoginForm handleLoading={handleLoading} />}
                    />
                    <Route
                        path="/Administrar_Producto/:id?"
                        element={<ProductForm handleLoading={handleLoading} />}
                    />
                    <Route path="/Productos" element={<ProductsList />} />

                    <Route
                        path="/Administrar_Ingrediente/:id?"
                        element={
                            <IngredientForm handleLoading={handleLoading} />
                        }
                    />
                    <Route path="/Ingredientes" element={<IngredientsList />} />
                    <Route path="/Proveedores" element={<AdminProveedores />} />
                    <Route path="/Ventas" element={<Carrito type="ventas" />} />
                    <Route path="/Gastos" element={<Carrito type="gastos" />} />
                    <Route path="/Caja" element={<FormCaja />} />
                    <Route path="/Mesas" element={<Listamesas />} />
                    <Route path="/Imprimir" element={<Maker />} />
                    <Route element={<SesionControl />} />
                </Routes>
                <SesionControl />
            </div>
        </BrowserRouter>
    )
}

ReactDOM.createRoot(document.getElementById('root')).render(<App />)
