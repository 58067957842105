import { jsPDF } from 'jspdf'

const imprimirFactura = () => {
    //ancho de la pagina en mm

    //const width = 58
    //Generar Factura
    const doc = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4',
    })
    //const doc = new jsPDF()
    const Titulo = 'Don Chorizo'
    const pageWidth = doc.internal.pageSize.getWidth()
    const pageX = pageWidth / 2
    doc.setFontSize(30)
    doc.text(Titulo, pageX, 10, 'center')
    doc.setFontSize(14)
    //Fecha y Hora
    const moment = require('moment')
    require('moment/locale/es')
    moment.locale('es')
    doc.text(
        `Fecha: ${moment().format('dddd, DD [de] MMMM [del] YYYY')}`,
        10,
        20
    )
    doc.text(`Hora: ${moment().format('h:mm:ss a')}`, pageX, 20, 'left')
    //doc.text('Ciente: Juan Alejandro León Lozano', 10, 40)
    //doc.text('NIT o CC: 1074189122', 10, 50)
    //doc.text('Productos', 10, 60)
    //doc.text(`Ancho de la pagina:  ${pageX}`, 10, 70)
    //doc.text('Total: $0000000', 10, 80)

    //Tabla
    const columns = ['Cant', 'Descripción', '$ Unidad', '$ Total']
    const data = [
        ['2', 'Chorizo Artesanal con Papa Criolla', '6.000', '12.000'],
        ['3', 'Chorizo Artesanal ', '2.000', '6.000'],
        ['1', 'Papa Criolla', '5.000', '5.000'],
    ]

    doc.autoTable({
        startY: 30,
        head: [columns],
        body: data,
    })

    //Imprimir
    doc.autoPrint({ variant: 'non-conform' })
    doc.save('autoprint.pdf')
}
export default imprimirFactura
