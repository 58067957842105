import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import * as Server from '../Server'

export const Listamesas = ({
    allData,
    setAllData,
    countData,
    setCountData,
    total,
    setTotal,
}) => {
    const [mesas, setMesas] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')

    const listamesas = async () => {
        try {
            const res = await Server.listaMesas()
            const data = await res.json()
            setMesas(data.mesas)
        } catch (err) {
            console.error(err)
        }
    }

    const searcher = (e) => {
        setSearch(e.target.value)
    }

    const nextPage = () => {
        if (
            mesas.filter((poke) => poke.numero.includes(search)).length >
            currentPage + 12
        )
            setCurrentPage(currentPage + 12)
    }

    const prevPage = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 12)
    }

    const normalizadSearch = search
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')

    useEffect(() => {
        listamesas()
    }, [])

    const administrarMesas = async (id) => {
        let miMesa = []
        let numero
        let descripcion
        try {
            if (id) {
                const res = await Server.listaMesa(id)
                const data = await res.json()
                console.log(data)
                miMesa = data.mesas
                console.log('miMesa')
                console.log(miMesa)
                numero = miMesa.numero
                descripcion = miMesa.descripcion
            } else {
                numero = ''
            }
            const messages = {
                required: 'Este campo es obligatorio',
                numero: 'Debe ingresar un numero',
            }

            Swal.fire({
                title: (id ? 'Modificar' : 'Crear') + ' Mesa',
                html: `<form id="Formulario" class="row g-3">
                        <a>Numero de mesa:</a><input id="numeroMesa" Value="${numero}" required data-validation-message="numero" class="swal2-input">
                        <div class="form-label">
                        <label for="inputAddress" class="form-label">Descripción</label>
                        <textarea class="form-control" placeholder="Descripción" id="descripcionMesa">${descripcion}</textarea>
                    </div>
                        </form>`,
                customClass: 'my-swal my-validation-message',
                showCancelButton: true,
                preConfirm: () => {
                    const form = document.querySelector('#Formulario')
                    const inputs = form.querySelectorAll('input')
                    let isValid = true

                    for (const input of inputs) {
                        if (!input.checkValidity()) {
                            isValid = false
                            const validationMessage = input.getAttribute(
                                'data-validation-message'
                            )
                            const errorMessage = messages[validationMessage]
                            Swal.showValidationMessage(errorMessage)
                            break
                        }
                    }

                    return isValid
                },
            }).then(async (result) => {
                if (result.value) {
                    let res
                    const numero = document.getElementById('numeroMesa').value
                    const descripcion =
                        document.getElementById('descripcionMesa').value
                    const mesa = {
                        numero,
                        descripcion,
                    }
                    if (id) {
                        res = await Server.modificarMesa(mesa, id)
                    } else {
                        res = await Server.crearMesa(mesa)
                    }

                    if (res.status === 200) {
                        Swal.fire({
                            html: `¡La mesa: <b>${numero}</b> ha sido ${
                                id ? 'modificada' : 'creada'
                            } exitosamente!`,
                            icon: 'success',
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload()
                            }
                        })
                    } else {
                        Swal.fire({
                            html:
                                '¡La mesa: <b>' +
                                numero +
                                '</b> No ha sido creada!',
                            icon: 'error',
                            allowOutsideClick: false,
                        })
                    }
                }
            })
        } catch (err) {
            console.error(err)
        }
    }

    const eliminarMesa = async (mesa) => {
        try {
            const res = await Server.eliminarMesa(mesa.id)
            if (res.status === 200) {
                Swal.fire({
                    html: `¡La Mesa <b>${mesa.numero}</b> ha sido eliminada correctamente!`,
                    icon: 'success',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                })
            } else {
                Swal.fire({
                    html: `¡La Mesa <b>${mesa.numero}</b> NO ha sido eliminada!`,
                    icon: 'error',
                    allowOutsideClick: false,
                })
            }
        } catch (error) {}
    }

    return (
        <>
            <div className="row">
                <div className="col-md-9 mb-4">
                    <label htmlFor="buscar" className="form-label">
                        Buscar
                    </label>
                    <input
                        type="text"
                        className="mb-2 form-control"
                        placeholder="Numero del Mesa"
                        value={search}
                        onChange={searcher}
                    />
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <button className="mb-2 btn btn-primary" onClick={prevPage}>
                    Anteriores
                </button>
                &nbsp;
                <button className="mb-2 btn btn-primary" onClick={nextPage}>
                    Siguientes
                </button>
                <button
                    className="mb-2 btn btn-success"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => administrarMesas()}
                    //href="Administrar_Ingrediente"
                >
                    Agregar Nueva Mesa
                </button>
            </div>

            <div className="row">
                {mesas.map((mesa) => {
                    if (
                        mesa.descripcion
                            .toLowerCase()
                            .includes(normalizadSearch.toLowerCase())
                    ) {
                        return (
                            <div className="col-md-3 mb-4" key={mesa.id}>
                                <div className="card card-body">
                                    <h5
                                        className="cart-title-custom"
                                        title={mesa.numero}
                                    >
                                        Mesa # {mesa.numero}
                                    </h5>
                                    <p className="card-text">
                                        <strong>Descripción: </strong>
                                        {mesa.descripcion === 'undefined'
                                            ? ''
                                            : mesa.descripcion}
                                    </p>
                                    <div className="row">
                                        <div className="mb-2 col-md-6 mb-4">
                                            <button
                                                className="col-md-12 btn btn-primary"
                                                disabled={mesa.editable}
                                                onClick={() =>
                                                    administrarMesas(mesa.id)
                                                }
                                            >
                                                Editar
                                            </button>
                                        </div>
                                        <div className="mb-2 col-md-6 mb-4">
                                            <button
                                                onClick={() =>
                                                    eliminarMesa(mesa)
                                                }
                                                rel="noopener noreferrer"
                                                className="col-md-12 btn btn-danger"
                                            >
                                                Eliminar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    return null
                })}
            </div>
        </>
    )
}

export default Listamesas
