import React, { useState, useEffect } from 'react'
import icono from '../../imagenes/productos.png'
// Components:
import ProductItem from './ProductItem'
import crearProducto from './ProductForm'
import * as Server from '../Server'
const ProductsList = () => {
    const [productos, setProductos] = useState([])
    const [categorias, setCategorias] = useState([])
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(1)
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')
    const [loadingProducts, setLoadingProducts] = useState(true)
    const [loadingCategories, setLoadingCategories] = useState(true)
    const listacategorias = async () => {
        try {
            setLoadingCategories(true)
            const res = await Server.listaCategorias()
            const data = await res.json()
            setCategorias(data.categorias)
            setLoadingCategories(false)
        } catch (err) {
            console.error(err)
            setLoadingCategories(false)
        }
    }
    const listaProductos = async () => {
        try {
            setLoadingProducts(true)
            const res = await Server.listaProductos()
            const data = await res.json()
            setProductos(data.productos)
            setLoadingProducts(false)
        } catch (err) {
            console.error(err)
            setLoadingProducts(false)
        }
    }
    useEffect(() => {
        listaProductos()
        listacategorias()
    }, [])

    const nextPage = () => {
        if (
            productos.filter((poke) => poke.nombre.includes(search)).length >
            currentPage + 12
        )
            setCurrentPage(currentPage + 12)
    }

    const prevPage = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 12)
    }

    const searcher = (e) => {
        setSearch(e.target.value)
    }

    const catFiltro = (e) => {
        setCategoriaSeleccionada(e.target.value)
    }

    const normalizadSearch = search
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    let todos = productos
    let resultado
    if (!search && parseInt(categoriaSeleccionada) === 1) {
        resultado = todos.slice(currentPage, currentPage + 12)
    } else if (search && parseInt(categoriaSeleccionada) > 1) {
        todos = productos.filter(
            (dato) =>
                dato.nombre
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
                    .includes(normalizadSearch.toLocaleLowerCase()) &&
                dato.categoria_id === parseInt(categoriaSeleccionada)
        )
        resultado = todos.slice(currentPage, currentPage + 12)
    } else if (search && parseInt(categoriaSeleccionada) === 1) {
        todos = productos.filter((dato) =>
            dato.nombre
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .includes(normalizadSearch.toLocaleLowerCase())
        )
        resultado = todos.slice(currentPage, currentPage + 12)
    } else if (!search && parseInt(categoriaSeleccionada) > 1) {
        todos = productos.filter(
            (dato) => dato.categoria_id === parseInt(categoriaSeleccionada)
        )
        resultado = todos.slice(currentPage, currentPage + 12)
    }
    /*useEffect(() => {
        const fetchProducts = async () => {
            const res = await Server.listaProductos();
            setProductos(res.data);
        };
        fetchProducts();
    }, []);*/
    const handleProductDelete = (id) => {
        setProductos(
            productos.filter((producto) => producto.id_producto !== id)
        )
    }
    //mensaje de carga mientras cargan todos los datos
    if (loadingProducts || loadingCategories) {
        return <div>Loading...</div>
    }
    return (
        <>
            <header>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h1>Productos</h1>
                </div>
                <div
                    className="container-cart-icon"
                    style={{ display: 'flex', flexDirection: 'row' }}
                >
                    <img src={icono} alt="Productos" />
                </div>
            </header>
            <div className="row">
                <div className="col-md-9 mb-4">
                    <label htmlFor="buscar" className="form-label">
                        Buscar
                    </label>
                    <input
                        type="text"
                        className="mb-2 form-control"
                        placeholder="Nombre del Producto"
                        value={search}
                        onChange={searcher}
                    />
                </div>
                <div className="col-md-3 mb-4">
                    <label htmlFor="categoria" className="form-label">
                        Categoria
                    </label>
                    <select
                        className="form-select"
                        value={parseInt(categoriaSeleccionada)}
                        onChange={catFiltro}
                    >
                        {categorias.map((categoriasItem) => (
                            <option
                                key={categoriasItem.id_categoria}
                                value={categoriasItem.id_categoria}
                            >
                                {categoriasItem.categoria}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <button className="mb-2 btn btn-primary" onClick={prevPage}>
                    Anteriores
                </button>
                &nbsp;
                <button className="mb-2 btn btn-primary" onClick={nextPage}>
                    Siguientes
                </button>
                <button
                    className="mb-2 btn btn-success"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => crearProducto()}
                >
                    Agregar Nuevo Producto
                </button>
            </div>
            <div className="row">
                {resultado.map((producto) => (
                    <ProductItem
                        key={producto.id_producto}
                        product={producto}
                        onProductDelete={handleProductDelete}
                    />
                ))}
            </div>
        </>
    )
}
export default ProductsList
