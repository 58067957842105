import { useState, useEffect } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
//const Navbar = () => {
// Verificar si el rol del usuario coincide con los roles permitidos para cada elemento de navegación
//    const isUserRoleAllowed = (allowedRoles) => allowedRoles.includes(localStorage.getItem('userRole'));
const userRole = 'admin'
const isUserRoleAllowed = (allowedRoles) => allowedRoles.includes(userRole)
const Navbar = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [showNavBar, setShowNavBar] = useState(false)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const cerrarSesion = () => {
        localStorage.clear()
        navigate('/login')
    }
    useEffect(() => {
        if (location.pathname === '/login') {
            setShowNavBar(false)
        } else {
            setShowNavBar(true)
        }
    }, [location])

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    /*const handleCloseMenu = () => {
        console.log('Ocultar')
        setIsMenuOpen(false)
    }*/

    if (!showNavBar) {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/Login">
                        <h1>POS</h1>
                    </a>
                </div>
            </nav>
        )
    }
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top justify-content-between">
            <div className="container-fluid">
                <a className="navbar-brand" href="/Ventas">
                    <h1>Don Chorizo</h1>
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleMenu}
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded={isMenuOpen}
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div
                    className={`collapse navbar-collapse justify-content-end ${
                        isMenuOpen ? 'd-flex show uno' : 'dos'
                    }`}
                    id="navbarNav"
                >
                    <ul className="navbar-nav ml-auto">
                        <li
                            className={`nav-item ${
                                isUserRoleAllowed(['admin', 'Gerente'])
                                    ? ''
                                    : 'd-none'
                            }`}
                        >
                            <NavLink
                                className="nav-link"
                                activeClassName="active"
                                onClick={toggleMenu}
                                exact
                                to="/Caja"
                            >
                                Inicio
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="nav-link"
                                activeClassName="active"
                                onClick={toggleMenu}
                                exact
                                to="/Ventas"
                            >
                                Ventas
                            </NavLink>
                        </li>
                        <li
                            className={`nav-item ${
                                isUserRoleAllowed(['admin', 'Gerente'])
                                    ? ''
                                    : 'd-none'
                            }`}
                        >
                            <NavLink
                                className="nav-link"
                                activeClassName="active"
                                onClick={toggleMenu}
                                exact
                                to="/Gastos"
                            >
                                Gastos
                            </NavLink>
                        </li>
                        <li
                            className={`nav-item ${
                                isUserRoleAllowed(['admin', 'Gerente'])
                                    ? ''
                                    : 'd-none'
                            }`}
                        >
                            <NavLink
                                className="nav-link"
                                activeClassName="active"
                                onClick={toggleMenu}
                                exact
                                to="/Productos"
                            >
                                Productos
                            </NavLink>
                        </li>
                        <li
                            className={`nav-item ${
                                isUserRoleAllowed(['admin', 'Gerente'])
                                    ? ''
                                    : 'd-none'
                            }`}
                        >
                            <NavLink
                                className="nav-link"
                                activeClassName="active"
                                onClick={toggleMenu}
                                exact
                                to="/Ingredientes"
                            >
                                Ingredientes
                            </NavLink>
                        </li>
                        <li
                            className={`nav-item ${
                                isUserRoleAllowed(['admin', 'Gerente'])
                                    ? ''
                                    : 'd-none'
                            }`}
                        >
                            <NavLink
                                className="nav-link"
                                activeClassName="active"
                                onClick={toggleMenu}
                                exact
                                to="/Proveedores"
                            >
                                Proveedores
                            </NavLink>
                        </li>
                        <li
                            className={`nav-item ${
                                isUserRoleAllowed(['admin', 'Gerente'])
                                    ? ''
                                    : 'd-none'
                            }`}
                        >
                            <NavLink
                                className="nav-link"
                                activeClassName="active"
                                onClick={toggleMenu}
                                exact
                                to="/Caja"
                            >
                                Caja
                            </NavLink>
                        </li>
                        <li
                            className={`nav-item ${
                                isUserRoleAllowed(['admin', 'Gerente'])
                                    ? ''
                                    : 'd-none'
                            }`}
                        >
                            <NavLink
                                className="nav-link"
                                activeClassName="active"
                                onClick={toggleMenu}
                                exact
                                to="/Mesas"
                            >
                                Mesas
                            </NavLink>
                        </li>
                        <li
                            className={`nav-item ${
                                isUserRoleAllowed(['admin', 'Gerente'])
                                    ? ''
                                    : 'd-none'
                            }`}
                        >
                            <NavLink
                                className="nav-link"
                                activeClassName="active"
                                onClick={toggleMenu}
                                exact
                                to="/Imprimir"
                            >
                                Imprimir
                            </NavLink>
                        </li>
                        <li
                            className={`nav-item ${
                                isUserRoleAllowed(['admin', 'Cajero'])
                                    ? ''
                                    : 'd-none'
                            }`}
                        >
                            <a className="nav-link disabled" href="/Usuarios">
                                Disabled
                            </a>
                        </li>
                        <li>
                            <button
                                onClick={cerrarSesion}
                                className="btn btn-primary btn-sm my-2 my-sm-0"
                            >
                                Cerrar sesion
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
export default Navbar
