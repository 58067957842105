import React, { useState } from 'react'
import Swal from 'sweetalert2'
import * as ServerG from './Server'
import * as ServerV from './Server'
import icono from '../imagenes/carrito-de-compras.png'
import generateTicket from '../utils/ticket.js'

export const Header = ({
    mesaSeleccionada,
    setMesaSeleccionada,
    allData,
    setAllData,
    total,
    countData,
    setCountData,
    setTotal,
    type,
    informacionPago,
    resultadoVenta,
}) => {
    const [active, setActive] = useState(false)

    const onDeleteData = (data) => {
        let id
        let valorI
        if (type === 'ventas') {
            id = data.id_producto
            valorI = data.precio
        } else {
            id = data.id_ingrediente
            valorI = data.costo
        }
        // Verificar si el item existe en el carrito

        if (
            allData.find(
                (item) => item.id_producto === id || item.id_ingrediente === id
            )
        ) {
            const products = allData.filter(
                (item) => item.id_producto !== id && item.id_ingrediente !== id
            )
            setTotal(total - valorI * data.quantity)
            setCountData(countData - data.quantity)
            setAllData([...products])

            localStorage.setItem(
                `Mesa_${mesaSeleccionada}`,
                JSON.stringify([...products])
            )
        } else {
            setTotal(total - valorI)
            setCountData(countData - data.quantity)
            setAllData([...allData, data])
            localStorage.setItem(
                `Mesa_${mesaSeleccionada}`,
                JSON.stringify([...allData, data])
            )
        }
    }

    const onAddData = (data) => {
        let id
        let valorI
        if (type === 'ventas') {
            id = data.id_producto
            valorI = data.precio
        } else {
            id = data.id_ingrediente
            valorI = data.costo
        }
        if (
            allData.find(
                (item) => item.id_producto === id || item.id_ingrediente === id
            )
        ) {
            const products = allData.map((item) =>
                item.id_producto === id || item.id_ingrediente === id
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
            )
            setTotal(total + valorI)
            setCountData(countData + 1)
            setAllData([...products])
            localStorage.setItem(
                `Mesa_${mesaSeleccionada}`,
                JSON.stringify([...products])
            )
        } else {
            setTotal(total + valorI)
            setCountData(countData + 1)
            setAllData([...allData, data])
            localStorage.setItem(
                `Mesa_${mesaSeleccionada}`,
                JSON.stringify([...allData, data])
            )
        }
    }

    const onDecreaseData = (data) => {
        let id
        let valorI
        if (type === 'ventas') {
            id = data.id_producto
            valorI = data.precio
        } else {
            id = data.id_ingrediente
            valorI = data.costo
        }
        if (
            allData.find(
                (item) => item.id_producto === id || item.id_ingrediente === id
            )
        ) {
            const products = allData.map((item) =>
                item.id_producto === id || item.id_ingrediente === id
                    ? { ...item, quantity: item.quantity - 1 }
                    : item
            )
            if (data.quantity === 1) {
                onDeleteData(data)

                return // Evitar agregar nuevamente el producto al carrito
            } else {
                setTotal(total - valorI)
                setCountData(countData - 1)
                setAllData([...products])
                localStorage.setItem(
                    `Mesa_${mesaSeleccionada}`,
                    JSON.stringify([...products])
                )
            }
        } else {
            setTotal(total - valorI)
            setCountData(countData - 1)
            setAllData([...allData, data])
            localStorage.setItem(
                `Mesa_${mesaSeleccionada}`,
                JSON.stringify([...allData, data])
            )
        }
    }

    const onMakingPayment = async () => {
        const { value: metodoPago, dismiss: cancelarMetodoPago } =
            await Swal.fire({
                title: 'Datos de pago',
                input: 'select',
                allowOutsideClick: false,
                inputOptions: {
                    Efectivo: 'Efectivo',
                    Nequi: 'Nequi',
                    Daviplata: 'Daviplata',
                    QR: 'QR',
                    BOLD: 'BOLD',
                },
                inputPlaceholder: 'Seleccione un metodo de pago',
                showCancelButton: true,
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if (value) {
                            resolve()
                        } else {
                            resolve('Seleccione un metodo de pago')
                        }
                    })
                },
            })

        if (cancelarMetodoPago) {
            return // Cancelar la ejecución del código
        }
        //Si se selecciona un metodo de pago
        if (metodoPago) {
            let valorRecibido = 0
            let cambio = 0
            if (metodoPago === 'Efectivo') {
                const {
                    value: valorRecibidoInput,
                    dismiss: cancelarValorRecibido,
                } = await Swal.fire({
                    title: 'Ingrese el valor recibido',
                    input: 'number',
                    inputLabel: 'Valor recibido',
                    inputValue: total,
                    inputAttributes: {
                        min: total,
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Calcular cambio',
                    inputValidator: (value) => {
                        if (!value || parseFloat(value) < total) {
                            return `Ingrese un valor válido, el valor debe ser igual o mayor a: $${total?.toLocaleString(
                                'es-CO'
                            )}`
                        }
                    },
                })

                if (cancelarValorRecibido) {
                    return // Cancelar la ejecución del código
                }

                if (valorRecibidoInput) {
                    const valorRecibidoParsed = parseFloat(valorRecibidoInput)
                    cambio = valorRecibidoParsed - total
                    valorRecibido = valorRecibidoParsed
                }
            }

            let res
            if (type === 'gastos') {
                res = await ServerG.crearGasto(total, metodoPago)
            } else if (type === 'ventas') {
                res = await ServerV.crearVenta(total, metodoPago)
                resultadoVenta = await res.json()
                const nFactura = resultadoVenta.factura
                informacionPago = [
                    metodoPago,
                    total,
                    valorRecibido,
                    cambio,
                    nFactura,
                ]
                //                generateTicket('print', allData, informacionPago)
            }
            ////AQUI SE DEBE CREAR LA OPCIÓN PARA IMRPIMIR
            for (let i = 0; i < allData.length; i++) {
                let res
                if (type === 'gastos') {
                    res = await ServerG.crearDetalleGasto(allData[i])
                } else if (type === 'ventas') {
                    res = await ServerV.crearDetalleVenta(allData[i])
                }
            }
            //DIFERENTE A EFECTIVO
            transaccionRealizada(total, type)
            setActive(!active)
            setAllData([])
            setTotal(0)
            setCountData(0)
            setMesaSeleccionada(null)
            localStorage.removeItem(`Mesa_${mesaSeleccionada}`)

            if (metodoPago === 'Efectivo') {
                Swal.fire({
                    title: 'Cambio a entregar',
                    html: `<em>Total: $${total?.toLocaleString(
                        'es-CO'
                    )}<br>Valor recibido: $${valorRecibido?.toLocaleString(
                        'es-CO'
                    )}<br></em><strong>Cambio: $${cambio?.toLocaleString(
                        'es-CO'
                    )}</strong>`,
                    icon: 'info',
                }).then((result) => {
                    if (result.isConfirmed) {
                        transaccionRealizada(total, type)
                    }
                })
            }
        }
    }

    const transaccionRealizada = (total, type) => {
        Swal.fire({
            title: '¡Exitoso!',
            text: `¡${
                type === 'gastos' ? 'Gasto' : 'Venta'
            } realizada por un valor de: $${total?.toLocaleString('es-CO')}!`,
            icon: 'success',
            //if(type) {},
            showCancelButton: true,
            confirmButtonText: 'Imprimir Factura',
            cancelButtonText: 'Finalizar Venta',
        }).then((result) => {
            if (result.isConfirmed) {
                generateTicket('print', allData, informacionPago)
            }
        })
    }

    const onClearCart = () => {
        Swal.fire({
            title: '¿Está seguro que desea vaciar el carrito?',
            text: `Después de vaciar el carrito deberá incluir todos los ${
                type === 'gastos' ? 'ingredientes' : 'productos'
            } nuevamente!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, vaciar el carrito', // Cambio: Agrega el texto para el botón de confirmar
            cancelButtonText: 'Cancelar', // Cambio: Agrega el texto para el botón de cancelar
            dangerMode: true,
        }).then((result) => {
            // Cambio: Renombra la variable a result
            if (result.isConfirmed) {
                // Cambio: Verifica si se confirmó la opción (botón OK)
                Swal.fire('El carrito está vacío!', {
                    icon: 'success',
                })
                setActive(!active)
                setAllData([])
                setTotal(0)
                setCountData(0)
                localStorage.removeItem(`Mesa_${mesaSeleccionada}`)
            } else {
                // Cambio: Esta parte se ejecuta si se seleccionó la opción cancelar
                Swal.fire('Puede continuar con la venta!', {
                    icon: 'info',
                })
            }
        })
    }
    return (
        <header className="sticky-top">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h1>
                    {mesaSeleccionada || type == 'gastos'
                        ? 'Carrito de compras'
                        : 'Mesas'}
                </h1>
            </div>
            <div className="container-icon">
                <div
                    className="container-cart-icon"
                    style={{ display: 'flex', flexDirection: 'row' }}
                    onClick={() => setActive(!active)}
                >
                    <img
                        src={icono}
                        alt={type === 'gastos' ? 'Ingredientes' : 'Productos'}
                    />
                    <div
                        className={
                            type === 'gastos'
                                ? 'count-ingredients'
                                : 'count-products'
                        }
                    >
                        <span id="contador-items">{countData}</span>
                    </div>
                </div>
                <div
                    className={`container-cart-items ${
                        active ? '' : 'hidden-cart'
                    }`}
                >
                    {allData.length ? (
                        <>
                            <div className="cart-title">
                                <h6
                                    className="cantidad col-md-1 mb-1"
                                    name="cantidad"
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Cant
                                </h6>
                                <h6
                                    className="nombre col-md-5 mb-1"
                                    name="nombre"
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Descripción
                                </h6>
                                <h6
                                    className="col-md-2 mb-1"
                                    name="Precio"
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    $ Unidad
                                </h6>
                                <h6
                                    className="col-md-2 mb-1"
                                    name="Precio"
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    $ Total
                                </h6>
                                <h6
                                    className="col-md-2 mb-1"
                                    name="Precio"
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Opc
                                </h6>
                            </div>
                            <div className="row-items" name="info_detalle">
                                {allData.map((data) => (
                                    <div
                                        className="cart-items"
                                        key={data.cartId}
                                    >
                                        <span
                                            className="cantidad-items-carrito col-md-1 mb-1"
                                            name="cantidad"
                                            style={{ textAlign: 'center' }}
                                        >
                                            {data.quantity}
                                        </span>
                                        <p
                                            className="titulo-items-carrito col-md-5 mb-1"
                                            name="nombre"
                                            style={{ textAlign: 'start' }}
                                        >
                                            {data.nombre}
                                        </p>
                                        <span
                                            className="precio-items-carrito col-md-2 mb-1"
                                            name="Precio"
                                            style={{ textAlign: 'center' }}
                                        >
                                            $
                                            {type === 'ventas'
                                                ? data.precio?.toLocaleString(
                                                      'es-CO'
                                                  )
                                                : data.costo?.toLocaleString(
                                                      'es-CO'
                                                  )}
                                        </span>
                                        <span
                                            className="precio-items-carrito col-md-2 mb-1"
                                            name="Precio"
                                            style={{ textAlign: 'center' }}
                                        >
                                            $
                                            {(
                                                data.quantity *
                                                (type === 'ventas'
                                                    ? data.precio
                                                    : data.costo)
                                            )?.toLocaleString('es-CO')}
                                        </span>
                                        <div
                                            className="opciones col-md-2 mb-1"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="icon-option icon-add"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                    onClick={() =>
                                                        onAddData(data)
                                                    }
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6 12h12 M12 6v12"
                                                    />
                                                </svg>
                                            </div>
                                            <div
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="icon-option icon-close"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                    onClick={() =>
                                                        onDecreaseData(data)
                                                    }
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6 12h12"
                                                    />
                                                </svg>
                                            </div>
                                            <div
                                                style={{
                                                    flex: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="icon-option icon-close"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                    onClick={() =>
                                                        onDeleteData(data)
                                                    }
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M6 18L18 6M6 6l12 12"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="cart-total-items">
                                <h1>Total: </h1>
                                <h2 className="total-pagar">
                                    ${total?.toLocaleString('es-CO')}
                                </h2>
                            </div>
                            <div>
                                <div className="container-btn-items">
                                    <button
                                        className="btn-pay"
                                        onClick={onMakingPayment}
                                    >
                                        Pagar
                                    </button>
                                    <button
                                        className="btn-clear-all"
                                        onClick={onClearCart}
                                    >
                                        Vaciar carrito
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <h5 className="cart-empty-items">
                            El carrito está vacío
                        </h5>
                    )}
                </div>
            </div>
        </header>
    )
}
