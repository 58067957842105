import React from 'react'
import * as IngredientServer from '../Server'
import crearIngrediente from './IngredientForm'
const IngredientItem = ({ ingredient, onIngredientDelete }) => {
    const onDeleteIngredient = async (id) => {
        const res = await IngredientServer.eliminarIngrediente(id)
        console.log('res')
        console.log(res)
        //validación de estado de respuesta if
        onIngredientDelete(id)
    }
    return (
        //crea las tarjetas de ingredientes
        <div className="col-md-3 mb-4" id={ingredient.id_ingrediente}>
            <div className="card card-body">
                <h5 className="cart-title-custom" title={ingredient.nombre}>
                    {ingredient.nombre}
                </h5>
                <p className="card-text">
                    <strong>Costo: </strong>$
                    {ingredient.costo?.toLocaleString('es-CO')}
                </p>
                <div className="row">
                    <div className="mb-2 col-md-6 mb-4">
                        <button
                            onClick={() =>
                                crearIngrediente(ingredient.id_ingrediente)
                            }
                            rel="noopener noreferrer"
                            className="col-md-12 btn btn-primary"
                        >
                            Modificar
                        </button>
                    </div>
                    <div className="mb-2 col-md-6 mb-4">
                        <button
                            onClick={() =>
                                onDeleteIngredient(ingredient.id_ingrediente)
                            }
                            rel="noopener noreferrer"
                            className="col-md-12 btn btn-danger"
                        >
                            Eliminar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default IngredientItem
