import React, { useState, useEffect } from 'react'
// Components:
import * as Server from '../Server'

export const Listproducts = ({
    mesaSeleccionada,
    setMesaSeleccionada,
    allData,
    setAllData,
    countData,
    setCountData,
    total,
    setTotal,
}) => {
    const [mesas, setMesas] = useState([])
    const [productos, setProducts] = useState([])
    const [categorias, setCategorias] = useState([])
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(1)
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')
    const [productosMesa, setProductosMesa] = useState([])
    const [estadoMesa, setEstadoMesa] = useState(null)

    useEffect(() => {
        if (localStorage.hasOwnProperty(`Mesa_${mesaSeleccionada}`)) {
            setEstadoMesa('Ocupada')
            //console.log('Existe - Estado: ' + estadoMesa)
        } else {
            setEstadoMesa('Disponible')
            //console.log('No Existe - Estado: ' + estadoMesa)
        }
        cargarProductos(mesaSeleccionada)
    }, [mesaSeleccionada])

    const cargarProductos = (mesa) => {
        try {
            const productosAlmacenados = JSON.parse(
                localStorage.getItem(`Mesa_${mesa}`)
            )
            if (productosAlmacenados) {
                setTotal((total) => {
                    let newTotal = total
                    for (const product of productosAlmacenados) {
                        newTotal += product.precio * product.quantity
                    }
                    return newTotal
                })

                setCountData((countData) => {
                    let newCountData = countData
                    for (const product of productosAlmacenados) {
                        newCountData += product.quantity
                    }
                    return newCountData
                })

                setAllData((allData) => [...allData, ...productosAlmacenados])

                /*localStorage.setItem(
                    `Mesa_${mesa}`,
                    JSON.stringify([...allData, ...productosAlmacenados])
                )*/
            }
        } catch (error) {
            console.error(error)
        }
    }

    const finalizarMesa = () => {
        setMesaSeleccionada(null)
        setProductosMesa([])
    }

    const listamesas = async () => {
        try {
            const res = await Server.listaMesas()
            const data = await res.json()
            setMesas(data.mesas)
        } catch (err) {
            console.error(err)
        }
    }

    const obtenerColorEstadoMesa = () => {
        return estadoMesa === 'Disponible'
            ? 'bg-success text-white col-md-8 align-self-center text-center rounded'
            : 'bg-danger text-white col-md-8 align-self-center text-center rounded'
    }

    const listacategorias = async () => {
        try {
            const res = await Server.listaCategorias()
            const data = await res.json()
            setCategorias(data.categorias)
        } catch (err) {
            console.error(err)
        }
    }

    const listaproductos = async () => {
        try {
            const res = await Server.listaProductos()
            const data = await res.json()
            setProducts(data.productos)
        } catch (err) {
            console.error(err)
        }
    }

    const searcher = (e) => {
        setSearch(e.target.value)
    }

    const nextPage = () => {
        if (
            productos.filter((poke) => poke.nombre.includes(search)).length >
            currentPage + 12
        )
            setCurrentPage(currentPage + 12)
    }

    const prevPage = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 12)
    }

    const catFiltro = (e) => {
        setCategoriaSeleccionada(e.target.value)
    }

    const normalizadSearch = search
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')

    useEffect(() => {
        listaproductos()
        listacategorias()
        listamesas()
    }, [])

    const back = () => {
        setMesaSeleccionada(null)
        setAllData([])
        setTotal(0)
        setCountData(0)
    }

    const onAddProduct = (product) => {
        if (allData.find((item) => item.id_producto === product.id_producto)) {
            const products = allData.map((item) =>
                item.id_producto === product.id_producto
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
            )
            setTotal(total + product.precio * product.quantity)
            setCountData(countData + product.quantity)
            setAllData([...products])
            localStorage.setItem(
                `Mesa_${mesaSeleccionada}`,
                JSON.stringify([...products])
            )
        } else {
            setTotal(total + product.precio)
            setCountData(countData + product.quantity)
            //const updatedData = // Agregar el nuevo producto a los productos existentes
            setAllData([...allData, product])

            localStorage.setItem(
                `Mesa_${mesaSeleccionada}`,
                JSON.stringify([...allData, product])
            )
        }
    }

    return (
        <>
            {!mesaSeleccionada ? (
                <>
                    <div className="row">
                        {mesas.map((mesa) => {
                            return (
                                <div
                                    className="col-md-3 mb-4"
                                    key={mesa.id}
                                    id={mesa.numero}
                                >
                                    <div
                                        className="card card-body"
                                        onClick={() =>
                                            setMesaSeleccionada(mesa.numero)
                                        }
                                    >
                                        <h3
                                            className="card-title"
                                            title={mesa.descripcion}
                                        >
                                            Mesa # {mesa.numero}
                                        </h3>
                                        <p className="card-text">
                                            <strong>Descripción: </strong>
                                            {mesa.descripcion}
                                        </p>
                                        <div className="row">
                                            <h6
                                                className={obtenerColorEstadoMesa()}
                                                alt="75x75"
                                            >
                                                {localStorage.hasOwnProperty(
                                                    `Mesa_${mesa.numero}`
                                                )
                                                    ? 'Ocupada'
                                                    : 'Disponible'}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
            ) : (
                <>
                    <div className="row">
                        <h3 className="col-md-3 mb-2">
                            Mesa # {mesaSeleccionada}
                        </h3>
                        <button
                            className="col-md-1 mb-1 btn btn-primary ml-auto"
                            onClick={back}
                        >
                            Regresar
                        </button>
                    </div>
                    <div className="row">
                        <div className="col-md-9 mb-4">
                            <label htmlFor="buscar" className="form-label">
                                Buscar
                            </label>
                            <input
                                type="text"
                                className="mb-2 form-control"
                                placeholder="Nombre del Producto"
                                value={search}
                                onChange={searcher}
                            />
                        </div>
                        <div className="col-md-3 mb-4">
                            <label htmlFor="categoria" className="form-label">
                                Categoria
                            </label>
                            <select
                                className="form-select"
                                value={parseInt(categoriaSeleccionada)}
                                onChange={catFiltro}
                            >
                                {categorias.map((categoria) => (
                                    <option
                                        key={categoria.id_categoria}
                                        value={categoria.id_categoria}
                                    >
                                        {categoria.categoria}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <button className="mb-2 btn btn-primary" onClick={prevPage}>
                        Anteriores
                    </button>
                    &nbsp;
                    <button className="mb-2 btn btn-primary" onClick={nextPage}>
                        Siguientes
                    </button>
                    <div className="row">
                        {productos.map((product) => {
                            if (
                                (parseInt(categoriaSeleccionada) === 1 ||
                                    product.categoria_id ===
                                        parseInt(categoriaSeleccionada)) &&
                                product.nombre
                                    .toLowerCase()
                                    .includes(normalizadSearch.toLowerCase())
                            ) {
                                return (
                                    <div
                                        className="col-md-3 mb-4"
                                        key={product.id_producto}
                                    >
                                        <div className="card card-body">
                                            <h5
                                                className="card-title"
                                                title={product.nombre}
                                            >
                                                {product.nombre}
                                            </h5>
                                            <p className="card-text">
                                                <strong>Precio: </strong>$
                                                {product.precio?.toLocaleString(
                                                    'es-CO'
                                                )}
                                            </p>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() =>
                                                    onAddProduct(product)
                                                }
                                            >
                                                Añadir al carrito
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                            return null
                        })}
                    </div>
                </>
            )}
        </>
    )
}

export default Listproducts
