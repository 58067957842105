import createPdf from './createPdf.js'
import logo from '../imagenes/logo.png'
const generateTicket = async (output, productosVendidos, informacionPago) => {
    //Fecha y Hora
    const moment = require('moment')
    require('moment/locale/es')
    moment.locale('es')
    const content = [
        //DATOS EMPRESA
        {
            image: logo,
            fit: [141.73, 56.692],
            alignment: 'center',
        },
        {
            text: 'Don Chorizo',
            style: 'header',
            fontSize: 20,
            margin: [0, 10, 0, 0],
        },
        { text: 'Ave Maria Pues!', style: 'header' },
        //{ text: 'Seattle Hanford St 1701, WA 98144', style: 'header' },
        { text: 'NIT: 1074188980', style: 'header' },

        //TIPO Y NUMERO DOCUMENTO
        {
            text: `FACTURA N°: ${informacionPago[4]}`,
            style: 'header',
            margin: [0, 10, 0, 2.25],
        },

        //DATOS CEBECERA FACTURAR

        {
            margin: [0, 10, 0, 0],
            table: {
                widths: ['25%', '35%', '15%', '25%'],
                body: [
                    [
                        { text: 'Fecha:', style: 'tHeaderLabel' },
                        {
                            text: `${moment().format('DD/MM/YYYY')}`,
                            style: 'tHeaderValue',
                            alignment: 'left',
                        },
                        { text: 'Hora:', style: 'tHeaderLabel' },
                        {
                            text: `${moment().format('h:mm a')}`,
                            style: 'tHeaderValue',
                        },
                    ],
                    [
                        { text: 'Cajero:', style: 'tHeaderLabel' },
                        {
                            text: 'Cajero 1',
                            style: 'tHeaderValue',
                            //colSpan: 3,
                        },
                        {},
                        {},
                    ],
                    [
                        { text: 'Cliente:', style: 'tHeaderLabel' },
                        {
                            text: 'Cliente 1',
                            style: 'tHeaderValue',
                            colSpan: 3,
                        },
                        {},
                        {},
                    ],
                    [
                        { text: 'NIT:', style: 'tHeaderLabel' },
                        {
                            text: '1111111111',
                            style: 'tHeaderValue',
                            colSpan: 3,
                        },
                        {},
                        {},
                    ],
                ],
            },
            layout: 'noBorders',
        },
        //TABLA PRODUCTOS
        {
            margin: [0, 10, 0, 0],
            table: {
                widths: ['10%', '50%', '20%', '20%'],
                headerRows: 2,
                body: [
                    //Encabezado
                    [
                        {
                            text: 'Can',
                            style: 'tProductsHeader',
                            decoration: 'underline',
                        },
                        {
                            text: 'Descripción',
                            style: 'tProductsHeader',
                            alignment: 'center',
                            decoration: 'underline',
                        },
                        {
                            text: 'Vr. Unit',
                            style: 'tProductsHeader',
                            alignment: 'right',
                            decoration: 'underline',
                        },
                        {
                            text: 'TOTAL',
                            style: 'tProductsHeader',
                            alignment: 'right',
                            decoration: 'underline',
                        },
                    ],
                    //Productos
                    ...productosVendidos.map((producto) => [
                        {
                            text: producto.quantity,
                            style: 'tProductsBody',
                            alignment: 'center',
                        },
                        {
                            text: producto.nombre,
                            style: 'tProductsBody',
                        },
                        {
                            text: producto.precio.toLocaleString('es-CO'),
                            style: 'tProductsBody',
                            alignment: 'right',
                        },
                        {
                            text: (
                                producto.precio * producto.quantity
                            ).toLocaleString('es-CO'),
                            style: 'tProductsBody',
                            alignment: 'right',
                        },
                    ]),
                ],
            },
            layout: {
                hLineWidth: function (i, node) {
                    return i === 0 || i === node.table.body.length ? 1 : 0
                },
                vLineWidth: function (i, node) {
                    return 0
                },
            },
        },
        {
            margin: [0, 10, 0, 0],
            table: {
                widths: ['25%', '25%', '25%', '25%'],
                body: [
                    //TOTALES
                    [
                        { text: 'TOTAL:', style: 'header', colSpan: 2 },
                        {},
                        {
                            text:
                                '$' +
                                informacionPago[1].toLocaleString('es-CO'),
                            style: 'header',
                            colSpan: 2,
                        },
                        {},
                    ],
                    //FORMAS PAGO
                    [
                        {
                            text: 'FORMA DE PAGO:',
                            style: 'tTotals',
                            alignment: 'left',
                            colSpan: 2,
                            margin: [0, 4, 0, 0],
                        },
                        {},
                        {
                            text: informacionPago[0],
                            style: 'tProductsBody',
                            colSpan: 2,
                        },
                        {},
                    ],
                    [
                        { text: 'Recibido:', style: 'tTotals' },
                        {
                            text:
                                '$' +
                                informacionPago[2].toLocaleString('es-CO'),
                            style: 'tTotals',
                        },
                        { text: 'Cambio:', style: 'tTotals' },
                        {
                            text:
                                '$' +
                                informacionPago[3].toLocaleString('es-CO'),
                            style: 'tTotals',
                        },
                    ],
                ],
            },
            layout: {
                hLineWidth: function (i, node) {
                    return i === 0 || i === node.table.body.length ? 1 : 0
                },
                vLineWidth: function (i, node) {
                    return 0
                },
            },
        },
        //NOTA DE PIE
        {
            text: 'Gracias por su visita!!!',
            style: 'text',
            alignment: 'center',
            margin: [0, 5],
        },
        //QR FACTURA
        /*{
            stack: [
                {
                    qr: '20603831404|03|B002|000131|724.94|4,752.30|30/09/2023|1|70477554|v2Ez4sKStje4NiqcXiuTcmTtPwgbrqgnXpWPltJKEhk=|',
                    fit: 115,
                    alignment: 'center',
                    eccLevel: 'Q',
                    margin: [0, 10, 0, 3],
                },
                {
                    text: 'Representación impresa del comprobante original. Consulta tu comprobante aquí:',
                    style: 'text',
                },
                {
                    text: 'https://x.microsoft.pse.pe/cpe/ace72300-0dfb-42d2-9ed7-0ba6e3cee01f',
                    link: 'https://x.microsoft.pse.pe/cpe/ace72300-0dfb-42d2-9ed7-0ba6e3cee01f',
                    style: 'link',
                },
            ],
        },*/
    ]

    const response = await createPdf({ content }, output)
    return response
}

export default generateTicket
