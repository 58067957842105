import Swal from 'sweetalert2'
import * as Server from '../Server'

const formatoMiles = (input) => {
    // Obtén el valor actual del campo de entrada
    let num = input.value.replace(/\D/g, '')

    // Aplica el formato de separación de miles
    num = num.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

    // Asigna el valor formateado al campo de entrada
    input.value = num
}

const crearIngrediente = async (id) => {
    let miIngrediente = []
    let nombre
    let id_proveedor
    let costo
    let descripcion

    console.log('id')
    console.log(id)
    try {
        if (id) {
            const res = await Server.listaIngrediente(id)
            const data = await res.json()
            miIngrediente = data.ingredientes[0]
            nombre = miIngrediente.nombre
            id_proveedor = miIngrediente.proveedor_id
            costo = miIngrediente.costo
            descripcion = miIngrediente.descripcion
        } else {
            nombre = ''
            id_proveedor = ''
            costo = ''
            descripcion = ''
        }
        const messages = {
            required: 'Este campo es obligatorio',
            nombre: 'Debe ingresar un nombre',
            proveedor: 'Debe seleccionar una proveedor',
            costo: 'Debes introducir un valor correcto en el costo',
        }

        const res = await Server.listaProveedores()
        const data = await res.json()
        let options = ''
        data.proveedores.forEach((proveedor) => {
            options += `<option value="${proveedor.id_prov}" ${
                proveedor.id_prov === id_proveedor ? 'selected' : ''
            }>${proveedor.nombre}</option>`
        })

        Swal.fire({
            title: (id ? 'Modificar' : 'Crear') + ' Ingrediente',
            html: `
                <form id="Formulario" class="row g-3">
                    <div class="col-md-9">
                        <label for="inputEmail4" class="form-label">Nombre</label>
                        <input type="text" class="form-control" id="nombreIngrediente" Value="${nombre}" required data-validation-message="nombre">
                    </div>

                    <div class="col-md-3">
                    <label for="inputState" class="form-label">Proveedor</label>
                    <select id="categoriaIngrediente" class="form-select">
                    ${options}
                    </select>
                </div>
                    
                    <div class="col-6">
                        <label for="inputAddress" class="form-label">Costo</label>
                        <div class="input-group mb-3">
                            <span class="input-group-text">$</span>
                            <input type="text" class="form-control" id="costoIngrediente" inputmode="numeric" pattern="[0-9.,]+" Value="${costo?.toLocaleString(
                                'es-CO'
                            )}"  required data-validation-message="costo">
                        </div>
                    </div>
                    
                    <div class="form-label">
                        <label for="inputAddress" class="form-label">Descripción</label>
                        <textarea class="form-control" placeholder="Descripción" id="descripcionIngrediente">${descripcion}</textarea>
                    </div>
                </form>`,
            customClass: 'my-swal my-validation-message',
            showCancelButton: true,
            preConfirm: () => {
                const form = document.querySelector('#Formulario')
                const inputs = form.querySelectorAll('input')
                let isValid = true

                for (const input of inputs) {
                    if (!input.checkValidity()) {
                        isValid = false
                        const validationMessage = input.getAttribute(
                            'data-validation-message'
                        )
                        const errorMessage = messages[validationMessage]
                        Swal.showValidationMessage(errorMessage)
                        break
                    }
                }

                return isValid
            },
        }).then(async (result) => {
            if (result.value) {
                let res
                const nombre =
                    document.getElementById('nombreIngrediente').value
                const proveedor = document.getElementById(
                    'categoriaIngrediente'
                ).value
                const costo = document
                    .getElementById('costoIngrediente')
                    .value.replace('.', '')
                const descripcion = document.getElementById(
                    'descripcionIngrediente'
                ).value

                const ingrediente = {
                    nombre,
                    proveedor,
                    costo,
                    descripcion,
                }
                if (id) {
                    res = await Server.modificarIngrediente(ingrediente, id)
                } else {
                    res = await Server.crearIngrediente(ingrediente)
                }

                if (res.status === 200) {
                    Swal.fire({
                        html: `¡El ingrediente: <b>${nombre}</b> ha sido ${
                            id ? 'modificado' : 'creado'
                        } exitosamente!`,
                        icon: 'success',
                        allowOutsideClick: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload()
                        }
                    })
                } else {
                    Swal.fire({
                        html: `¡El proveedor: <b>' +
                            nombre +
                            '</b> No ha sido ${id ? 'modificado' : 'creado'}!`,
                        icon: 'error',
                        allowOutsideClick: false,
                    })
                }
            }
        })

        // Agrega la función formatoMiles al evento input de los elementos de entrada de costo
        const costoIngrediente = document.getElementById('costoIngrediente')
        costoIngrediente.addEventListener('input', () =>
            formatoMiles(costoIngrediente)
        )
    } catch (err) {
        console.error(err)
    }
}

export default crearIngrediente
