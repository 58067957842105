import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import Chart from 'chart.js/auto'
import Swal from 'sweetalert2'
import imprimirFactura from '../imprimirjsPDF'
import * as Server from '../Server'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import 'jspdf-autotable'

const FormCaja = () => {
    const [registro, setRegistro] = useState([])
    const [operacion, setOperacion] = useState(null)
    const [fechaCierre, setFechaCierre] = useState(null)
    const [valorCierre, setValorCierre] = useState(null)
    const [totalVentas, setTotalVentas] = useState(null)
    const [totalGastos, setTotalGastos] = useState(null)
    const [fechaInicio, setFechaInicio] = useState('')
    const [fechaFin, setFechaFin] = useState('')
    const [ventasPorMetodo, setVentasPorMetodo] = useState({})
    const [gastosPorMetodo, setGastosPorMetodo] = useState({})
    const [graficoVentas, setGraficoVentas] = useState(null)
    const [graficoGastos, setGraficoGastos] = useState(null)

    const obtenerVentasPorMetodo = (registrosVentas) => {
        const ventasPorMetodo = {}

        if (registrosVentas && registrosVentas.length > 0) {
            registrosVentas.forEach((registro) => {
                const metodoPago = registro.metodoPago
                const cantidad = ventasPorMetodo[metodoPago]?.cantidad || 0

                ventasPorMetodo[metodoPago] = {
                    cantidad: cantidad + 1,
                    total:
                        (ventasPorMetodo[metodoPago]?.total || 0) +
                        parseInt(registro.total, 10),
                }
            })
        }

        return ventasPorMetodo
    }

    const obtenerGastosPorMetodo = (registrosGastos) => {
        const gastosPorMetodo = {}

        if (registrosGastos && registrosGastos.length > 0) {
            registrosGastos.forEach((registro) => {
                const metodoPago = registro.metodoPago
                const cantidad = gastosPorMetodo[metodoPago]?.cantidad || 0

                gastosPorMetodo[metodoPago] = {
                    cantidad: cantidad + 1,
                    total:
                        (gastosPorMetodo[metodoPago]?.total || 0) +
                        parseInt(registro.total, 10),
                }
            })
        }

        return gastosPorMetodo
    }

    const consultarRegistros = async () => {
        try {
            const resRegistroVentas = await Server.registroVentas({
                fecha_inicio: fechaInicio,
                fecha_fin: fechaFin,
            })
            const ventas = await resRegistroVentas.json()
            const ventasPorMetodo = obtenerVentasPorMetodo(
                ventas.registrosVentas
            )
            setVentasPorMetodo(ventasPorMetodo)

            const resRegistroGastos = await Server.registroGastos({
                fecha_inicio: fechaInicio,
                fecha_fin: fechaFin,
            })
            const gastos = await resRegistroGastos.json()
            const gastosPorMetodo = obtenerGastosPorMetodo(
                gastos.registrosGastos
            )
            setGastosPorMetodo(gastosPorMetodo)
        } catch (err) {
            console.error(err)
        }
    }

    const generarGraficos = async (ventasPorMetodo, gastosPorMetodo) => {
        Chart.register(ChartDataLabels)
        const graficoVentasCanvas = document
            .getElementById('graficoVentas')
            .getContext('2d')
        if (graficoVentas) {
            graficoVentas.destroy()
        }
        const nuevoGraficoVentas = new Chart(graficoVentasCanvas, {
            type: 'pie',
            data: {
                labels: Object.keys(ventasPorMetodo),
                datasets: [
                    {
                        label: 'Ventas por Método de Pago',
                        data: Object.values(ventasPorMetodo).map(
                            (metodo) => metodo.cantidad
                        ),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                plugins: {
                    responsive: true,
                    labels: Object.keys(ventasPorMetodo),
                    datalabels: {
                        formatter: (value, graficoVentas) => {
                            const cantidadRegistros =
                                graficoVentas.dataset.data.reduce(
                                    (a, b) => a + b,
                                    0
                                )
                            const totalMetodo =
                                ventasPorMetodo[
                                    graficoVentas.chart.data.labels[
                                        graficoVentas.dataIndex
                                    ]
                                ].total

                            const percentage =
                                ((value / cantidadRegistros) * 100).toFixed(1) +
                                '%'
                            return `${
                                graficoVentas.chart.data.labels[
                                    graficoVentas.dataIndex
                                ]
                            }: \n $${totalMetodo?.toLocaleString(
                                'es-CO'
                            )} - (${percentage})`
                        },
                        color: '#000',
                        font: {
                            weight: 'bold',
                        },
                    },
                },
            },
        })
        setGraficoVentas(nuevoGraficoVentas)

        const graficoGastosCanvas = document
            .getElementById('graficoGastos')
            .getContext('2d')
        if (graficoGastos) {
            graficoGastos.destroy()
        }
        const nuevoGraficoGastos = new Chart(graficoGastosCanvas, {
            type: 'pie',
            data: {
                labels: Object.keys(gastosPorMetodo),
                datasets: [
                    {
                        label: 'Ventas por Método de Pago',
                        data: Object.values(gastosPorMetodo).map(
                            (metodo) => metodo.cantidad
                        ),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                plugins: {
                    responsive: true,
                    labels: Object.keys(gastosPorMetodo),
                    datalabels: {
                        formatter: (value, graficoGastos) => {
                            const cantidadRegistros =
                                graficoGastos.dataset.data.reduce(
                                    (a, b) => a + b,
                                    0
                                )
                            const totalMetodo =
                                gastosPorMetodo[
                                    graficoGastos.chart.data.labels[
                                        graficoGastos.dataIndex
                                    ]
                                ].total
                            const percentage =
                                ((value / cantidadRegistros) * 100).toFixed(1) +
                                '%'
                            return `${
                                graficoGastos.chart.data.labels[
                                    graficoGastos.dataIndex
                                ]
                            }: \n $${totalMetodo?.toLocaleString(
                                'es-CO'
                            )} - (${percentage})`
                        },
                        color: '#000',
                        font: {
                            weight: 'bold',
                        },
                    },
                },
            },
        })
        setGraficoGastos(nuevoGraficoGastos)
    }

    const obtenerClaseColor = () => {
        if (operacion === 'Inicio') {
            return 'btn btn-danger col-md-1 mb-4 text-left'
        } else {
            return 'btn btn-success col-md-1 mb-4 text-left'
        }
    }
    const obtenerColorEstado = () => {
        if (operacion === 'Inicio') {
            return 'bg-success text-white  col-md-6 mb-4 text-center rounded'
        } else {
            return 'bg-danger text-white  col-md-6 mb-4 text-center rounded'
        }
    }

    const Operacion = async () => {
        let htmlarea = ''
        let closeConfirm
        const monedas = [
            100000, 50000, 20000, 10000, 5000, 2000, 1000, 500, 200, 100, 50,
        ]
        //Se realiza la apertura
        if (operacion !== 'Inicio') {
            htmlarea = `<input id="valor" type="number" placeholder="Valor" class="swal2-input" style="width: 80%" required>
      <textarea id="observaciones" placeholder="Observaciones" class="swal2-textarea" style="width: 80%"></textarea>`
        }
        //Se va a realizar el Cierre
        else {
            if (fechaInicio !== fechaCierre) {
                closeConfirm = false
            } else {
                closeConfirm = true
            }
            htmlarea = `<div style="display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 10px;">
    ${monedas
        .map(
            (m) =>
                `<div>
          $${m}
          <input id="input${m}" type="number" class="swal2-input" style="width: 30%; height:30px" required>
        </div>`
        )
        .join('\n')}</div>`
            htmlarea += `<div>
          Fecha a Cerrar: <p><text id="fecha-cierre">${fechaCierre}</></p>
          Total esperado: <p>$<text id="total-esperado">${valorCierre}</></p>
          Total: <p>$<text id="total"><ref={totalref}/></></p>
          <textarea id="observaciones" placeholder="Observaciones" class="swal2-textarea" style="width: 80%"></textarea>
        </div>`
        }
        Swal.fire({
            title: 'Ingresar información',
            html: htmlarea,
            showCloseButton: closeConfirm,
            showCancelButton: closeConfirm,
            focusConfirm: true,
            confirmButtonText: 'Guardar',
            allowOutsideClick: false,
            customClass: { confirmButton: 'custom-confirm-button-class' },
            preConfirm: async () => {
                let valor
                if (operacion !== 'Inicio') {
                    valor = Swal.getPopup().querySelector('#valor').value
                    if (!valor) {
                        Swal.showValidationMessage(
                            'Por favor, Ingrese el valor'
                        )
                        return false
                    }
                    if (!/^\d+$/.test(valor)) {
                        Swal.showValidationMessage(
                            'El campo "valor" solo acepta números'
                        )
                        return false
                    }
                } else {
                    const fechaCierre =
                        Swal.getPopup().querySelector('#fecha-cierre').value
                    console.log('fecha cierre')
                    console.log(fechaCierre)
                    const totalParrafo = Swal.getPopup().querySelector('#total')
                    valor = totalParrafo.textContent.trim()
                }
                const observaciones =
                    Swal.getPopup().querySelector('#observaciones').value
                let res
                if (operacion !== 'Inicio') {
                    res = await Server.registrarOperacion(
                        'Inicio',
                        fechaCierre,
                        valor,
                        observaciones
                    )
                } else {
                    res = await Server.registrarOperacion(
                        'Cierre',
                        fechaCierre,
                        valor,
                        observaciones
                    )
                    console.log('res')
                    console.log(res)
                }
                Swal.fire({
                    title: 'Información ingresada',
                    html: `Valor: $${valor}<br>Observaciones: ${observaciones}`,
                    icon: 'success',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                })
            },
            didOpen: () => {
                if (operacion === 'Inicio') {
                    const checkValues = () => {
                        const totalEsperadoParrafo =
                            Swal.getPopup().querySelector('#total-esperado')
                        const totalParrafo =
                            Swal.getPopup().querySelector('#total')
                        const confirmButton = Swal.getPopup().querySelector(
                            'button.swal2-confirm'
                        )
                        const totalEsperado =
                            totalEsperadoParrafo.textContent.trim()
                        const total = totalParrafo.textContent.trim()
                        if (totalEsperado !== total) {
                            confirmButton.disabled = true
                            confirmButton.textContent = 'No Habilitado'
                        } else {
                            confirmButton.disabled = false
                            confirmButton.textContent =
                                'Realizar cierre de caja'
                        }
                    }
                    for (const m of monedas) {
                        const input = Swal.getPopup().querySelector(
                            `#input${m}`
                        )
                        input.addEventListener('input', function () {
                            calculateTotal()
                            checkValues()
                        })
                        checkValues()
                        const observer = new MutationObserver(checkValues)
                        const totalParrafo =
                            Swal.getPopup().querySelector('#total')
                        observer.observe(totalParrafo, {
                            characterData: true,
                            subtree: true,
                        })
                    }
                }
            },
        })

        function calculateTotal() {
            let total = 0
            for (const m of monedas) {
                const input = Swal.getPopup().querySelector(`#input${m}`)
                const inputValue = parseInt(input.value) || 0
                total += m * inputValue
            }
            Swal.getPopup().querySelector('#total').textContent = total
        }
    }

    useEffect(() => {
        const obtenerDatosIniciales = async () => {
            const bogotaTimezone = moment()
            const fechaActual = bogotaTimezone.format('YYYY-MM-DD')
            setFechaInicio(fechaActual)
            setFechaFin(fechaActual)

            try {
                const resUltimoRegistro = await Server.ultimoRegistro()
                const data = await resUltimoRegistro.json()
                // Actualiza los estados con los datos obtenidos
                setOperacion(data.cajas.operacion)
                setFechaCierre(data.cajas.fecha_hora.substring(0, 10))
                setValorCierre(data.cajas.valor_cierre)
                setTotalVentas(data.cajas.total_ventas)
                setTotalGastos(data.cajas.total_gastos)
                setRegistro(data)

                const resRegistroVentas = await Server.registroVentas({
                    fecha_inicio: fechaActual,
                    fecha_fin: fechaActual,
                })
                const ventas = await resRegistroVentas.json()
                const ventasPorMetodo = obtenerVentasPorMetodo(
                    ventas.registrosVentas
                )
                setVentasPorMetodo(ventasPorMetodo)

                const resRegistroGastos = await Server.registroGastos({
                    fecha_inicio: fechaActual,
                    fecha_fin: fechaActual,
                })
                const gastos = await resRegistroGastos.json()
                const gastosPorMetodo = obtenerGastosPorMetodo(
                    gastos.registrosGastos
                )
                setGastosPorMetodo(gastosPorMetodo)

                //generarGraficos(ventasPorMetodo);
            } catch (err) {
                console.error(err)
            }
        }

        obtenerDatosIniciales()
    }, [])

    useEffect(() => {
        if (JSON.stringify(registro) !== '[]') {
            localStorage.setItem('Estado Caja', operacion)
            localStorage.setItem('Cierre', valorCierre)

            if (operacion === 'Cierre') {
                //Cuando el ultimo registro es cierre y se requiere realizar el inicio de caja
                Swal.fire({
                    title: 'Apertura de Caja',
                    text: 'Realice la apertura de caja para poder continuar',
                    icon: 'warning',
                    //buttons: ["Cancelar","Si, vaciar el carrito"],
                    dangerMode: true,
                })
            } else if (fechaInicio !== fechaCierre) {
                Swal.fire({
                    title: 'Cierre de caja obligatorio',
                    text: `Realice el cierre de caja para la fecha ${fechaCierre}`,
                    icon: 'warning',
                    //buttons: ["Cancelar","Si, vaciar el carrito"],
                    dangerMode: true,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        Operacion()
                    }
                })
            } else {
            }
        }
    }, [registro])

    //Generar los graficos
    useEffect(
        () => {
            generarGraficos(ventasPorMetodo, gastosPorMetodo)
        },
        [ventasPorMetodo, gastosPorMetodo],
        generarGraficos
    )

    return (
        <div>
            <h2 className="mb-3 text-center">Administrar Caja</h2>

            <div className="row justify-content-between align-items-center">
                <div className="row col-md-2 mb-4 justify-content-between align-items-center">
                    <h4 className="col-md-6 mb-4 text-right">Estado:</h4>
                    <p className={obtenerColorEstado()}>
                        {operacion === 'Inicio' ? 'Abierta' : 'Cerrada'}
                    </p>
                </div>
                <button
                    onClick={Operacion}
                    type="submit"
                    className={obtenerClaseColor()}
                >
                    {operacion === 'Inicio' ? 'Cierre' : 'Apertura'}
                </button>
            </div>
            <div className="row justify-content-between align-items-center">
                <h4 className="col-md-4 mb-4 text-right">
                    Total Ventas en Efectivo: $
                    {totalVentas?.toLocaleString('es-CO')}
                </h4>
                <h4 className="col-md-4 mb-4 text-right">
                    Total Gastos en Efectivo: $
                    {totalGastos?.toLocaleString('es-CO')}
                </h4>
                <h4 className="col-md-4 mb-4 text-right">
                    Total Cierre Caja: ${valorCierre?.toLocaleString('es-CO')}
                </h4>
            </div>
            <div className="row align-items-right">
                <div className="col-md-4 mb-4 text-right">
                    <label htmlFor="start">Fecha de Inicio:</label>
                    <input
                        type="date"
                        id="start"
                        name="trip-start"
                        class="btn btn-outline-dark"
                        value={fechaInicio}
                        onChange={(e) => setFechaInicio(e.target.value)}
                    />
                </div>
                <div className="col-md-4 mb-4 text-right">
                    <label htmlFor="end">Fecha de Fin:</label>
                    <input
                        type="date"
                        id="end"
                        name="trip-end"
                        class="btn btn-outline-dark"
                        value={fechaFin}
                        max={fechaFin}
                        onChange={(e) => setFechaFin(e.target.value)}
                    />
                </div>
                <div className="col-md-4 mb-4 text-right">
                    <button
                        onClick={consultarRegistros}
                        type="button"
                        class="btn btn-outline-primary"
                    >
                        Consultar
                    </button>
                    <button
                        onClick={imprimirFactura}
                        type="button"
                        class="btn btn-outline-primary"
                    >
                        Imprimir
                    </button>
                </div>
            </div>
            <div className="row justify-content-between align-items-center">
                <div className="canvas col-md-3 mb-3 text-right">
                    <canvas id="graficoVentas"></canvas>
                </div>
                <div className="canvas col-md-3 mb-3 text-right">
                    <canvas id="graficoGastos"></canvas>
                </div>
            </div>
        </div>
    )
}

export default FormCaja
