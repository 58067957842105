import React from 'react'
import * as ProductServer from '../Server'
import crearProducto from './ProductForm'

const ProductItem = ({ product, onProductDelete }) => {
    const onDeleteProduct = async (id) => {
        const res = await ProductServer.eliminarProducto(id)
        console.log('res')
        console.log(res)
        //validación de estado de respuesta if
        onProductDelete(id)
    }
    return (
        //crea las tarjetas de productos
        <div className="col-md-3 mb-4" id={product.id_producto}>
            <div className="card card-body">
                <h5 className="cart-title-custom" title={product.nombre}>
                    {product.nombre}
                </h5>
                <p className="card-text">
                    <strong>Precio: </strong>$
                    {product.precio?.toLocaleString('es-CO')}
                </p>
                <div className="row">
                    <div className="mb-2 col-md-6 mb-4">
                        <button
                            onClick={() => crearProducto(product.id_producto)}
                            rel="noopener noreferrer"
                            className="col-md-12 btn btn-primary"
                        >
                            Modificar
                        </button>
                    </div>
                    <div className="mb-2 col-md-6 mb-4">
                        <button
                            onClick={() => onDeleteProduct(product.id_producto)}
                            rel="noopener noreferrer"
                            className="col-md-12 btn btn-danger"
                        >
                            Eliminar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductItem
