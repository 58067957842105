import React, { useState, useEffect } from 'react'
import icono from '../../imagenes/productos.png'
// Components:
import IngredientItem from './IngredientItem'
import crearIngrediente from './IngredientForm'
//import useCheck from '../useCheck'
import * as IngredientServer from '../Server'
const IngredientsList = () => {
    //useCheck()
    const [ingredientes, setIngredientes] = useState([])
    const [proveedores, setProveedores] = useState([])
    const [proveedorSeleccionado, setProveedorSeleccionado] = useState(1)
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')
    const [loadingIngredients, setLoadingIngredients] = useState(true)
    const [loadingProveedors, setLoadingProveedors] = useState(true)
    const listaproveedores = async () => {
        try {
            setLoadingProveedors(true)
            const res = await IngredientServer.listaProveedores()
            const data = await res.json()
            setProveedores(data.proveedores)
            setLoadingProveedors(false)
        } catch (err) {
            console.error(err)
            setLoadingProveedors(false)
        }
    }
    const listaIngredientes = async () => {
        try {
            setLoadingIngredients(true)
            const res = await IngredientServer.listaIngredientes()
            const data = await res.json()
            setIngredientes(data.ingredientes)
            setLoadingIngredients(false)
        } catch (err) {
            console.error(err)
            setLoadingIngredients(false)
        }
    }
    useEffect(() => {
        listaIngredientes()
        listaproveedores()
    }, [])
    const nextPage = () => {
        if (
            ingredientes.filter((poke) => poke.nombre.includes(search)).length >
            currentPage + 12
        )
            setCurrentPage(currentPage + 12)
    }
    const prevPage = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 12)
    }
    const searcher = (e) => {
        setSearch(e.target.value)
    }
    const catFiltro = (e) => {
        setProveedorSeleccionado(e.target.value)
    }
    const normalizadSearch = search
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    let todos = ingredientes
    let resultado
    if (!search && parseInt(proveedorSeleccionado) === 1) {
        //resultado = ingredientes.slice(currentPage, currentPage + 12)
        resultado = todos.slice(currentPage, currentPage + 12)
    } else if (search && parseInt(proveedorSeleccionado) > 1) {
        todos = ingredientes.filter(
            (dato) =>
                dato.nombre
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
                    .includes(normalizadSearch.toLocaleLowerCase()) &&
                dato.proveedor_id === parseInt(proveedorSeleccionado)
        )
        resultado = todos.slice(currentPage, currentPage + 12)
    } else if (search && parseInt(proveedorSeleccionado) === 1) {
        todos = ingredientes.filter((dato) =>
            dato.nombre
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .toLowerCase()
                .includes(normalizadSearch.toLocaleLowerCase())
        )
        resultado = todos.slice(currentPage, currentPage + 12)
    } else if (!search && parseInt(proveedorSeleccionado) > 1) {
        todos = ingredientes.filter(
            (dato) => dato.proveedor_id === parseInt(proveedorSeleccionado)
        )
        resultado = todos.slice(currentPage, currentPage + 12)
    }
    /*useEffect(() => {
        const fetchIngredients = async () => {
            const res = await IngredientServer.listaIngredientes();
            setIngredientes(res.data);
        };
        fetchIngredients();
    }, []);*/
    const handleIngredientDelete = (id) => {
        setIngredientes(
            ingredientes.filter(
                (ingrediente) => ingrediente.id_ingrediente !== id
            )
        )
    }
    //mensaje de carga mientras cargan todos los datos
    if (loadingIngredients || loadingProveedors) {
        return <div>Loading...</div>
    }
    return (
        <>
            <header>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h1>Ingredientes</h1>
                </div>
                <div
                    className="container-cart-icon"
                    style={{ display: 'flex', flexDirection: 'row' }}
                >
                    <img src={icono} alt="Ingredientes" />
                </div>
            </header>
            <div className="row">
                <div className="col-md-9 mb-4">
                    <label htmlFor="buscar" className="form-label">
                        Buscar
                    </label>
                    <input
                        type="text"
                        className="mb-2 form-control"
                        placeholder="Nombre del Ingrediente"
                        value={search}
                        onChange={searcher}
                    />
                </div>
                <div className="col-md-3 mb-4">
                    <label htmlFor="proveedor" className="form-label">
                        Proveedor
                    </label>
                    <select
                        className="form-select"
                        value={parseInt(proveedorSeleccionado)}
                        onChange={catFiltro}
                    >
                        {proveedores.map((proveedoresItem) => (
                            <option
                                key={proveedoresItem.id_prov}
                                value={proveedoresItem.id_prov}
                            >
                                {proveedoresItem.nombre}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <button className="mb-2 btn btn-primary" onClick={prevPage}>
                    Anteriores
                </button>
                &nbsp;
                <button className="mb-2 btn btn-primary" onClick={nextPage}>
                    Siguientes
                </button>
                <button
                    className="mb-2 btn btn-success"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => crearIngrediente()}
                >
                    Agregar Nuevo Ingrediente
                </button>
            </div>
            <div className="row">
                {resultado.map((ingrediente) => (
                    <IngredientItem
                        key={ingrediente.id_ingrediente}
                        ingredient={ingrediente}
                        onIngredientDelete={handleIngredientDelete}
                    />
                ))}
            </div>
        </>
    )
}
export default IngredientsList
