import React, { useState, useEffect } from 'react'
import { Header } from './Header'
import { Listproducts } from './Ventas/ListaProductos'
import { Listingredientes } from './Gastos/ListaIngredientes'

export const Carrito = ({ type }) => {
    const [allData, setAllData] = useState([])
    const [countData, setCountData] = useState(0)
    const [total, setTotal] = useState(0)
    const [mesaSeleccionada, setMesaSeleccionada] = useState(null)
    const seleccionarMesa = (mesa) => {
        setMesaSeleccionada(mesa)
    }

    useEffect(() => {
        setMesaSeleccionada(null)
        setAllData([])
        setTotal(0)
        setCountData(0)
    }, [type])

    return (
        <>
            <Header
                mesaSeleccionada={mesaSeleccionada}
                setMesaSeleccionada={setMesaSeleccionada}
                allData={allData}
                setAllData={setAllData}
                total={total}
                countData={countData}
                setCountData={setCountData}
                setTotal={setTotal}
                type={type}
            />
            {type === 'ventas' ? (
                <Listproducts
                    mesaSeleccionada={mesaSeleccionada}
                    setMesaSeleccionada={setMesaSeleccionada}
                    allData={allData}
                    setAllData={setAllData}
                    countData={countData}
                    setCountData={setCountData}
                    total={total}
                    setTotal={setTotal}
                />
            ) : (
                <Listingredientes
                    allData={allData}
                    setAllData={setAllData}
                    countData={countData}
                    setCountData={setCountData}
                    total={total}
                    setTotal={setTotal}
                />
            )}
        </>
    )
}
