import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import * as Server from '../Server'

export const AdminProveedores = ({
    allData,
    setAllData,
    countData,
    setCountData,
    total,
    setTotal,
}) => {
    const [proveedores, setProveedores] = useState([])
    const [proveedorSeleccionado] = useState(1)
    const [currentPage, setCurrentPage] = useState(0)
    const [search, setSearch] = useState('')

    const listaproveedores = async () => {
        try {
            const res = await Server.listaProveedores()
            const data = await res.json()
            setProveedores(data.proveedores)
        } catch (err) {
            console.error(err)
        }
    }

    const searcher = (e) => {
        setSearch(e.target.value)
    }

    const nextPage = () => {
        if (
            proveedores.filter((poke) => poke.nombre.includes(search)).length >
            currentPage + 12
        )
            setCurrentPage(currentPage + 12)
    }

    const prevPage = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 12)
    }

    const normalizadSearch = search
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')

    useEffect(() => {
        listaproveedores()
    }, [])

    const administrarProveedores = async (id) => {
        let miProveedor = []
        let nombre
        try {
            if (id) {
                const res = await Server.listaProveedor(id)
                const data = await res.json()
                console.log(data)
                miProveedor = data.proveedores
                console.log('miProveedor')
                console.log(miProveedor)
                nombre = miProveedor.nombre
            } else {
                nombre = ''
            }
            const messages = {
                required: 'Este campo es obligatorio',
                nombre: 'Debe ingresar un nombre',
            }

            Swal.fire({
                title: (id ? 'Modificar' : 'Crear') + ' Proveedor',
                html: `<form id="Formulario" class="row g-3">
                        <a>Nombre:</a><input id="nombreProveedor" Value="${nombre}" required data-validation-message="nombre" class="swal2-input">
                        </form>`,
                customClass: 'my-swal my-validation-message',
                showCancelButton: true,
                preConfirm: () => {
                    const form = document.querySelector('#Formulario')
                    const inputs = form.querySelectorAll('input')
                    let isValid = true

                    for (const input of inputs) {
                        if (!input.checkValidity()) {
                            isValid = false
                            const validationMessage = input.getAttribute(
                                'data-validation-message'
                            )
                            const errorMessage = messages[validationMessage]
                            Swal.showValidationMessage(errorMessage)
                            break
                        }
                    }

                    return isValid
                },
            }).then(async (result) => {
                if (result.value) {
                    let res
                    const nombre =
                        document.getElementById('nombreProveedor').value
                    const proveedor = {
                        nombre,
                    }
                    if (id) {
                        res = await Server.modificarProveedor(proveedor, id)
                    } else {
                        res = await Server.crearProveedor(proveedor)
                    }

                    if (res.status === 200) {
                        Swal.fire({
                            html: `¡El proveedor: <b>${nombre}</b> ha sido ${
                                id ? 'modificado' : 'creado'
                            } exitosamente!`,
                            icon: 'success',
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload()
                            }
                        })
                    } else {
                        Swal.fire({
                            html:
                                '¡El proveedor: <b>' +
                                nombre +
                                '</b> No ha sido creado!',
                            icon: 'error',
                            allowOutsideClick: false,
                        })
                    }
                }
            })
        } catch (err) {
            console.error(err)
        }
    }

    const eliminarProveedor = async (proveedor) => {
        try {
            const res = await Server.eliminarProveedor(proveedor.id_prov)
            if (res.status === 200) {
                Swal.fire({
                    html: `¡El Proveedor <b>${proveedor.nombre}</b> ha sido eliminado correctamente!`,
                    icon: 'success',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                })
            } else {
                Swal.fire({
                    html: `¡El Proveedor <b>${proveedor.nombre}</b> NO ha sido eliminado!`,
                    icon: 'error',
                    allowOutsideClick: false,
                })
            }
        } catch (error) {}
    }

    return (
        <>
            <div className="row">
                <div className="col-md-9 mb-4">
                    <label htmlFor="buscar" className="form-label">
                        Buscar
                    </label>
                    <input
                        type="text"
                        className="mb-2 form-control"
                        placeholder="Nombre del Proveedor"
                        value={search}
                        onChange={searcher}
                    />
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <button className="mb-2 btn btn-primary" onClick={prevPage}>
                    Anteriores
                </button>
                &nbsp;
                <button className="mb-2 btn btn-primary" onClick={nextPage}>
                    Siguientes
                </button>
                <button
                    className="mb-2 btn btn-success"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => administrarProveedores()}
                    //href="Administrar_Ingrediente"
                >
                    Agregar Nuevo Proveedor
                </button>
            </div>

            <div className="row">
                {proveedores.map((proveedor, index) => {
                    if (
                        (proveedorSeleccionado === 1 ||
                            proveedor.proveedor_id === proveedorSeleccionado) &&
                        proveedor.nombre
                            .toLowerCase()
                            .includes(normalizadSearch.toLowerCase())
                    ) {
                        return (
                            <div
                                className="col-md-3 mb-4"
                                key={proveedor.id_prov}
                            >
                                <div className="card card-body">
                                    <h5
                                        className="cart-title-custom"
                                        title={proveedor.nombre}
                                    >
                                        {proveedor.nombre}
                                    </h5>
                                    <div className="row">
                                        <div className="mb-2 col-md-6 mb-4">
                                            <button
                                                className="col-md-12 btn btn-primary"
                                                disabled={proveedor.editable}
                                                onClick={() =>
                                                    administrarProveedores(
                                                        proveedor.id_prov
                                                    )
                                                }
                                            >
                                                Editar
                                            </button>
                                        </div>
                                        <div className="mb-2 col-md-6 mb-4">
                                            <button
                                                onClick={() =>
                                                    eliminarProveedor(proveedor)
                                                }
                                                rel="noopener noreferrer"
                                                className="col-md-12 btn btn-danger"
                                            >
                                                Eliminar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    return null
                })}
            </div>
        </>
    )
}

export default AdminProveedores
