import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
//import * as Server from '../Server'

const SesionControl = ({ children }) => {
    const navigate = useNavigate()

    useEffect(() => {
        function tokenCaducado() {
            const tokenExpiration = localStorage.getItem('tokenExpiration')
            // Comprobar si el token de expiración está presente
            if (tokenExpiration) {
                var expirationTime = parseInt(tokenExpiration)

                return new Date().getTime() >= expirationTime
            }
            return true // Si no hay token de expiración, considerarlo caducado
        }

        function redirigirAInicioSesion() {
            navigate('/login')
            localStorage.clear()
            // Aquí colocas la lógica de redirigir al usuario a la página de inicio de sesión
            // ...
        }

        function refrescarSesion(e) {
            localStorage.setItem(
                'tokenExpiration',
                parseInt(localStorage.getItem('tokenExpiration')) + 6000
            )

            if (
                e.target.tagName === 'BUTTON' &&
                e.target.innerText === 'Cerrar sesion'
            ) {
                return
            }

            iniciarControlSesion()

            // Aquí colocas la lógica para refrescar la sesión
            // ...
        }

        function iniciarControlSesion() {
            const token = localStorage.getItem('token')
            const estado_caja = localStorage.getItem('Estado Caja')

            // Verificar si el token no existe o ha caducado
            if (!token || tokenCaducado()) {
                // Redireccionar al inicio de sesión
                redirigirAInicioSesion()
            } else if (estado_caja === 'Cierre') {
                navigate('/Caja')
            }

            /*
            // Aquí inicias el control de sesión
            if (tokenCaducado()) {
                
                redirigirAInicioSesion()
            } else {
                
                refrescarSesion()
            }
            */
        }

        iniciarControlSesion()

        // Agregar event listener para el evento 'mousemove'
        window.addEventListener('click', refrescarSesion)

        // Remover event listener al desmontar el componente
        return () => {
            window.removeEventListener('click', refrescarSesion)
        }
    }, [navigate])

    return <>{children}</>
}

export default SesionControl
